import { tokenStore, userStore } from '../stores';
import { apiFetchData } from "../common/apiRequest";
import {
  User,
  CreateSessionResponse,
  CreateUserResponse,
  UserToken,
} from "../models/user";
import { notification } from '../common/notification';
import { EVT_USER_LOGGED_IN } from '../events';

export const refreshToken = async (): Promise<UserToken> => {
  const userToken = tokenStore.value;
  if (!userToken) {
    throw new Error("No user token found");
  }
  return apiFetchData<UserToken>({
    url: '/auth/refresh',
    method: "POST",
    withToken: true,
    params: {
      refresh_token: userToken.refresh_token
    }
  });
};

export const getCurrentUserProfile = async (): Promise<User> => {
  return apiFetchData<User>({
    url: '/user/me',
    method: "GET",
    withToken: true,
  });
};

export const createUserByName = async (name: string): Promise<CreateUserResponse> => {
  const ipAddr = (
    await (await fetch("https://api.ipify.org?format=json")).json()
  ).ip;
  return createUser(name, ipAddr);
};

export const createUser = async (
  name: string,
  ip: string
): Promise<CreateUserResponse> =>
  apiFetchData<CreateUserResponse>({
    url: "/user",
    method: "POST",
    params: {
      name: name,
      ip,
    },
  });

export const createSession = async (
  userId?: string
): Promise<CreateSessionResponse> =>
  apiFetchData<CreateSessionResponse>({
    url: "/session",
    method: "POST",
    params: {
      user_id: userId,
    },
  });

export const setUserProfileToStorage = (user: User) => {
  userStore.value = user;
};

export const getUserProfileFromStorage = (): User | null => {
  return userStore.value;
};

export const cleanUserProfileFromStorage = () => {
  userStore.clear();
  localStorage.removeItem("userId");
};

export const getUserTokenFromStorage = (): UserToken | null => {
  return tokenStore.value;
};

export const setUserTokenToStorage = (token: UserToken) => {
  tokenStore.value = token;
  if (!tokenStore.value && token) {
    notification.emit(EVT_USER_LOGGED_IN, token);
  }
};

export const cleanUserToken = () => {
  tokenStore.clear();
};

export const logout = async () => {
  cleanUserToken();
  cleanUserProfileFromStorage();
  await notification.emit(EVT_USER_LOGGED_IN, {});
  // redirect to the home page
  location.href = `${window.location.protocol}//${window.location.host}`;
};