import { useHistory } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import BackIcon from "../../components/icon/BackIcon";

export const TermsPage: React.FC = () => {
  const history = useHistory();
  return (
    <StyledWrapper>
      <NavWrapper>
        <IconWrapper>
          <NavName onClick={() => {
            history.push('/');
          }}>
          <BackIcon />
        </NavName>
        </IconWrapper>
      </NavWrapper>
      <TermsOfService/>
    </StyledWrapper>
  );
};

const TermsOfService = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Terms of Service</h1>
      <p><strong>Last updated: 2024/09/18</strong></p>
      <p>
        These Terms of Service (these “Terms”, the “Terms of Service”) apply to your access to and use of the website,
        other online products, and services (collectively, our “Services”) provided by shop4u.ai (“Shop4U”, “we”, “us”, or “our”).
      </p>
      <p>
        By clicking your consent (e.g. “Create”, “Continue”, “Sign-in”, or “Sign-up”) or by using our Services, you agree to be bound by these Terms.
        These Terms apply to all visitors, users, and others who access or use the Service, including our website. We reserve the right, at our sole discretion,
        to restrict, suspend, or terminate your access to and use of the Service.
      </p>
      <p>
        If you’re agreeing to the Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on
        that organization or entity’s behalf and bind them to these Terms (in which case, the references to “you” and “your” in these Terms refer to that organization or entity).
      </p>
      <p>
        Please see our Privacy Policy for information about how we collect, use, share, and otherwise process information about you.
      </p>

      <h2>Our Services</h2>
      <p>
        The Services include a product comparison and content generation service, which allows you to search for products,
        compare these products, and interact with chatbot features that act as a "super filter” to help you find what you need.
        The service is available via <a href="https://shop4u.ai" target="_blank" rel="noopener noreferrer">https://shop4u.ai</a>.
        You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.
      </p>

      <h2>Acceptable Use</h2>
      <p>To use our Services, you must be at least 13 years old. If you are under 18, you must have your parent or legal guardian’s permission to use the Service. Additionally, you may not use the Services if:</p>
      <ul>
        <li>(a) you are barred from accessing, using, or receiving the Services under applicable laws; or</li>
        <li>(b) your Account (as defined below) is suspended, terminated, or not in good standing.</li>
      </ul>
      <p>
        You may need to register for an account (an “Account”) to access some or all of our Services. You consent to us collecting and using your email address
        and any other information you provide to create your Account. You must notify us of any changes to the information you provide and keep your information
        accurate, complete, and up-to-date. You agree not to share, transfer, or sell your Account or share your Account or password with anyone else.
      </p>
      <p>
        We grant you, subject to your complete and ongoing compliance with these Terms, a non-exclusive, non-transferable, non-sublicensable, revocable license to:
      </p>
      <ul>
        <li>(a) access and use the Shop4U Services we make available to you;</li>
        <li>(b) copy and display Content created with or submitted to the Services by other users of the Services (“User Content”) and modify User Content.</li>
      </ul>
      <p>
        We do not grant you any rights owned by our licensors (including any advertisers, affiliates, and partners) or third-party software governed by its own
        terms (e.g., open source software). We and any of our licensors reserve all rights, title, and interest not expressly granted under the Terms, and it is
        your responsibility to comply with any applicable third-party or open source license terms. All rights not expressly granted to you in this section are
        reserved by us.
      </p>
      <p>
        You agree to refrain from utilizing the Service in any way that could potentially result in damage or compromise its availability. You also agree not to engage in any
        unlawful, illegal, fraudulent, or harmful activities through the Service, including but not limited to acts of hate, harassment, violence, political manipulation, spam,
        or malware. In addition, you agree that you will not publish or promote any inappropriate User Content using the Services. Inappropriate content includes, but is not
        limited to:
      </p>
      <ul>
        <li>Explicit Material: Content that includes nudity, sexual acts, or explicit imagery.</li>
        <li>Violent or Graphic Content: Images or descriptions that depict violence, gore, or other graphic material.</li>
        <li>Hate Speech and Discrimination: Language or imagery that promotes hatred, discrimination, or violence against individuals or groups based on race, ethnicity, national origin, religion, gender, sexual orientation, disability, or other protected categories.</li>
        <li>Harassment and Bullying: Content that intimidates, demeans, or harasses individuals or specific groups of people.</li>
        <li>False Information and Misrepresentation: Deliberately misleading or fraudulent information or impersonation of individuals or organizations.</li>
        <li>Illegal Activities: Promotion of illegal activities or content that violates laws and regulations.</li>
        <li>Spam and Malware: Unsolicited promotions, commercial messages, or content that contains harmful software.</li>
        <li>Infringes, violates, or misappropriates the intellectual property rights of any other person.</li>
      </ul>
      <p>
        You hereby agree to refrain from conducting any systematic or automated data collection activities, including scraping, datamining, extraction, or harvesting,
        either on or in relation to the Service. Such prohibited activities include, but are not limited to, utilizing the Service as an input for other services, websites,
        or databases. Additionally, you agree not to republish any content generated by the Service without clearly acknowledging its source and the associated context. Any
        misrepresentation of the content's origin or the nature of its creation is strictly prohibited.
      </p>
      <p>
        You agree to use the Services only for your own internal, personal, non-commercial use, and not on behalf of, or for the benefit of, any third party.
      </p>
      <p>
        We reserve the right to remove any User Content, terminate an Account, or otherwise restrict access to the Services to ensure compliance with these Terms.
      </p>

      <h2>User Content</h2>
      <p>
        The Service allows you to upload, submit, store, send, or receive Content. You are solely responsible for the Content you upload, post, email, transmit,
        or otherwise make accessible through the Service. You will comply with any requirements or restrictions imposed on usage of User Content by their respective
        owners. We exercise no control over the Content posted through the Service and, therefore, cannot guarantee its accuracy, integrity, or quality. You understand
        that by using the Service, you may encounter Content that is offensive, inappropriate, or objectionable. We shall not be held liable in any way for any Content,
        including but not limited to any errors or omissions within Content, or any losses or damages incurred as a result of using any Content shared, emailed,
        transmitted, or otherwise made accessible through the Service.
      </p>
      <p>
        You consent to assess and assume all risks associated with using any Content, including any reliance on its accuracy, comprehensiveness, or usefulness. In this
        context, you acknowledge that you cannot rely on any Content generated by or submitted to the Service. By using the Services you release and hold harmless us from
        any and all liability arising from your use of any third party website or service. Any interactions with other users of the Services are solely between you and such other
        users and we are not responsible for any loss or damage that may result from such interactions, and we are not under any obligation to become involved in any dispute
        between users of the Services unless there is a violation of these Terms or our Privacy Policy. In the event of any dispute between you and any other user of the Services,
        you hereby release us, our officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected,
        disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. If you are a California resident, you shall and hereby do waive California
        Civil Code Section 1542, which states: “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his, her, or its favor
        at the time of executing the release, and that, if known by him or her, would have materially affected his settlement with the debtor or released party.”
      </p>
      <p>
        You acknowledge and agree that we may retain Content and, if legally obligated, may disclose Content as well.
      </p>

      <h2>Intellectual Property Rights</h2>
      <p>All Content at Shop4U is the property of us and/or any applicable third-party licensors. Without our prior written consent, you may not copy, store, publish, modify, transmit, create derivative works based on, distribute, or otherwise use our Content, except in connection with your use of or access to the Services. You retain ownership of any intellectual property rights to Content that belongs to you and is posted, uploaded, or otherwise made available using the Services.</p>

      <h2>Limitation of Liability</h2>
      <p>IN NO CIRCUMSTANCES SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, PARTNERS, AGENTS, SUPPLIERS, OR AFFILIATES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, ARISING FROM YOUR USE OF THE SERVICE.</p>

      <h2>Geoblocking</h2>
      <p>We reserve the right to restrict access to the Services based on geographic locations to comply with legal, compliance, or business considerations.</p>

      <h2>Disclaimer</h2>
      <p>YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. OUR SERVICES, INCLUDING USER CONTENT, ARE PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT WARRANTIES OF ANY KIND.</p>

      <h2>Indemnification</h2>
      <p>To the fullest extent permitted by applicable law, you will indemnify, defend, and hold harmless us and our employees, officers, directors, agents, partners from all losses, liabilities, claims, demands, damages, or costs arising from your use and access to the Service, any violation of these Terms, or the Content uploaded on the Service.</p>

      <h2>Amendments</h2>
      <p>We may make changes to these Terms from time to time. If significant revisions are made, we will endeavor to provide a notice at least 30 days prior to the implementation of any new terms.</p>

      <h2>Termination</h2>
      <p>You’re free to stop using our Services at any time. We reserve the right to suspend or terminate your access to the Services with or without notice.</p>

      <h2>Governing Law and Dispute</h2>
      <p>These Terms and your use of the Services shall be governed by the laws of the State of [Your State], without respect to its conflict of laws principles. Disputes will be resolved exclusively through binding arbitration.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at <a href="mailto:support@shop4u.ai">support@shop4u.ai</a>.</p>
    </div>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  background: #f7f6f9;
  padding-top: 30px;
  position: fixed;
  top: 0;
  z-index: 9999;
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const NavName = styled("div")`
  height: 30px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #0d0319;
`;

