import React from "react";

const ThumbsUpIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="fi:thumbs-up">
        <path
          id="Vector"
          d="M7 22.6343H4C3.46957 22.6343 2.96086 22.4236 2.58579 22.0485C2.21071 21.6734 2 21.1647 2 20.6343V13.6343C2 13.1038 2.21071 12.5951 2.58579 12.2201C2.96086 11.845 3.46957 11.6343 4 11.6343H7M14 9.63428V5.63428C14 4.83863 13.6839 4.07557 13.1213 3.51296C12.5587 2.95035 11.7956 2.63428 11 2.63428L7 11.6343V22.6343H18.28C18.7623 22.6397 19.2304 22.4707 19.5979 22.1583C19.9654 21.8459 20.2077 21.4112 20.28 20.9343L21.66 11.9343C21.7035 11.6476 21.6842 11.355 21.6033 11.0765C21.5225 10.7981 21.3821 10.5406 21.1919 10.3218C21.0016 10.103 20.7661 9.92821 20.5016 9.80949C20.2371 9.69078 19.9499 9.63099 19.66 9.63428H14Z"
          stroke="#74697B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export default ThumbsUpIcon;
