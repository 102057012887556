import { getEnvFromHostname } from '../components/url-config';

interface AppConfig {
  env?: string;
  APP_NAME: string;
  API_URL: string;
  AUTH_API_URL: string;
  MIXPANEL_PROJECT_TOKEN: string;
  MIXPANEL_PROJECT_DEBUG: boolean;
  APP_VERSION: string;
  GOOGLE_CLIENT_ID: string;
}

const envFromHostname = getEnvFromHostname();
export const isProductionEnvironment = envFromHostname === 'production';

let envConfig = {};
// let env = 'dev';
try {
  switch (envFromHostname) {
    case 'production':
      envConfig = require('./config-production.json');
      break;
    case 'staging':
      envConfig = require('./config-staging.json');
      break;
    default:
      envConfig = require('./config-dev.json');
      break;
  }
} catch (err) {
  console.error(err);
}

export const getEnvName = (): string => envFromHostname;

const defaultConfig: AppConfig = {
  // Add any dev or default configs here, but note that they will be overridden
  // by values in `src/config.json` if present.
  APP_NAME: 'shop4u-ai',
  API_URL: 'https://api.shop4u.ai',
  AUTH_API_URL: 'https://api.shop4u.ai',
  MIXPANEL_PROJECT_TOKEN: 'xxxxxxx',
  MIXPANEL_PROJECT_DEBUG: false,
  APP_VERSION: '1.0.0',
  GOOGLE_CLIENT_ID: '367165242844-h31crcb80eftbmtk9f1b2pnt75vhrq9v.apps.googleusercontent.com',
};

const appConfig: AppConfig = {
  ...defaultConfig,
  ...envConfig,
  env: envFromHostname,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.setConfig = (env: string) => {
  try {
    switch (env) {
      case 'production':
        envConfig = require('./config-production.json');
        break;
      case 'staging':
        envConfig = require('./config-staging.json');
        break;
      default:
        envConfig = appConfig;
        env = appConfig.env?? 'local';
        break;
    }
    appConfig.env = env;
    Object.keys(envConfig).forEach(k => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      appConfig[k] = envConfig[k];
    });
  } catch (err) {
    console.error(err);
  }
};

export default appConfig;
