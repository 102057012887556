import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";

import { routingPaths } from "../../common/constants";
import { mpTrackEnterPage } from "../../common/mixPanel";
import LoadingScreen from "../../components/LoadingScreen";
import { LoginContext } from "./LoginContext";

interface PrivateRouteProps {
  authenticated: boolean;
  path: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  authenticated,
  path,
  children,
}) => {
  const { setUrl } = useContext(LoginContext);
  useEffect(() => {
    switch (path) {
      case routingPaths.home:
        document.title = "Shop4u";
        break;
      case routingPaths.login:
        document.title = "Login";
        break;
      case routingPaths.product:
        document.title = "Product";
        break;
      case routingPaths.privacyPolicy:
        document.title = "Privacy Policy";
        break;
      case routingPaths.suggestions:
        document.title = "Trending Comparisions";
        break;
      default:
        document.title = "Shop4u";
        break;
    }
    mpTrackEnterPage(path.replace(/\/:\w+/, "").replace(/\//, "_"));
  }, [path]);
  if (!authenticated) {
    const url = window.location.pathname + window.location.search;
    setUrl(url);
    return <Redirect to={routingPaths.login} />;
  } else {
    return (
      <Route path={path} exact={path === routingPaths.home}>
        {children}
      </Route>
    );
  }
};
