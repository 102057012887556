import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addProduct } from "../../services/product";
import { createUserByName, createSession } from "../../services/user";
import { AppContext } from "../../context/AppContext";
import { ProductPage } from "../product/ProductPage";
import { exchangeSessionIdBySnapshotName } from "../../services/suggestions";
import { getSessionImage } from "../../services/product";

export const SnapshotPage = () => {
  const {
    state: { userName, userId, sessionIds },
    dispatch,
  } = useContext(AppContext);
  const { snapshotId } = useParams<{ snapshotId: string }>();
  const [sessionId, setSessionId] = useState<string>("");
  useEffect(() => {
    exchangeSessionIdBySnapshotName(`/snapshot/${snapshotId}`).then(
      async (sessionId) => {
        const thumbnails = (await getSessionImage(sessionId)).thumbnails;
        if (!userId) {
          const createUserRes = await createUserByName(userName);
          dispatch({ type: "SET_USER_ID", payload: createUserRes.id });
        } else if (!sessionIds || !sessionIds.includes(sessionId)) {
          const createSessionRes = await createSession(userId);
          dispatch({ type: "ADD_SESSION_ID", payload: createSessionRes.id });
          await Promise.all(
            thumbnails.map((thumbnail) =>
              addProduct(createSessionRes.id, thumbnail.url)
            )
          );
          setSessionId(createSessionRes.id);
        } else {
          setSessionId(sessionId);
        }
      }
    );

    return () => {
      setSessionId("");
    };
  }, [snapshotId, userId]);

  return sessionId !== '' ? (
    <ProductPage sessionId={sessionId} />
  ) : (
    <></>
  );
};
