import { ChatMessage } from '../models/services/api';
import { apiFetchData } from "../common/apiRequest";

export const getSuggestedQuestions = async (sessionId: string): Promise<string[]> => {
  return apiFetchData<{recommended_questions: string[]}>({
    url: '/suggested/questions',
    params: {session_id: sessionId},
    method: "GET",
  }).then(data => data.recommended_questions);
};

export const getChatHistory = async (sessionId: string): Promise<ChatMessage[]> => {
  return apiFetchData<ChatMessage[]>({
    url: '/session/chat',
    params: {session_id: sessionId},
    method: "GET",
  });
}