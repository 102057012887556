import React, { useEffect } from 'react';
import { facebookTokenExchange } from '../../services/integrations';

// A custom hook to load the Facebook SDK
const useFacebookSDK = () => {
    useEffect(() => {
        // Check if the SDK script is already added
        const scriptExists = document.getElementById('facebook-jssdk');
        if (scriptExists) return;

        // Adding the Facebook SDK script to the body
        const script = document.createElement('script');
        script.id = 'facebook-jssdk';
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
        document.body.appendChild(script);

        // Initialize SDK after it loads
        window.fbAsyncInit = () => {
            window.FB?.init({
                appId: '532262652705977',
                cookie: true,
                xfbml: true,
                version: 'v20.0', // use the latest version
            });
        };

        // Cleanup
        return () => {
            const existingScript = document.getElementById('facebook-jssdk');
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, []);
};


const FacebookLoginButton: React.FC = () => {
    useFacebookSDK();

    const handleFacebookLogin = () => {
        if (window.FB) {
            window.FB.login(response => {
                console.log('Facebook login response:', response);
                facebookTokenExchange(response.authResponse.accessToken);
            }, { scope: 'public_profile,email' });
        }
    };

    return (
        <button onClick={handleFacebookLogin}>
            Login with Facebook
        </button>
    );
};

export default FacebookLoginButton;