import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { mpTrackAction, mpTrackError } from '../../common/mixPanel';
import { LoginContext } from './LoginContext';

export const SignInPage: React.FC<{ to?: string }> = ({ to }) => {
  const { getUrl } = useContext(LoginContext);
  const history = useHistory();
  const targetUrl = to || getUrl() || '/';

  useEffect(() => {
    if (targetUrl) {
      history.push(targetUrl);
      mpTrackAction('signIn');
    } else {
      mpTrackError('getUserTokenFromLocalStorage', {
        error: 'Failed to getUserTokenFromLocalStorage by localStorage',
      });
    }
  }, [targetUrl]);

  return null;
};
