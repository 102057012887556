import React from "react";

const AIIcon: React.FC = () => {
  return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="AI">
              <path id="Union" fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.5 3.5C16.4163 3.5 16.3505 3.56916 16.3266 3.64936C16.2896 3.77378 16.2055 3.91616 16.0749 4.25766C15.8953 4.72748 15.6345 5.26991 15.3107 5.77529C14.8848 6.44016 14.2265 7.0002 13.6887 7.34823C12.6029 8.05081 11.9388 8.27453 11.6455 8.33408C11.5659 8.35024 11.5 8.41878 11.5 8.5C11.5 8.58122 11.5659 8.64977 11.6455 8.66592C11.9388 8.72547 12.6029 8.94919 13.6887 9.65177C14.2265 9.9998 14.8848 10.5598 15.3107 11.2247C15.6345 11.7301 15.8953 12.2725 16.0749 12.7423C16.2055 13.0838 16.2896 13.2262 16.3266 13.3506C16.3505 13.4308 16.4163 13.5 16.5 13.5C16.5837 13.5 16.6495 13.4308 16.6734 13.3506C16.7104 13.2262 16.7945 13.0838 16.9251 12.7423C17.1047 12.2725 17.3655 11.7301 17.6893 11.2247C18.1152 10.5598 18.7735 9.9998 19.3113 9.65177C20.3971 8.94919 21.0612 8.72547 21.3545 8.66592C21.4341 8.64977 21.5 8.58122 21.5 8.5C21.5 8.41878 21.4341 8.35024 21.3545 8.33408C21.0612 8.27453 20.3971 8.05081 19.3113 7.34823C18.7735 7.0002 18.1152 6.44016 17.6893 5.77529C17.3655 5.26991 17.1047 4.72748 16.9251 4.25766C16.7945 3.91616 16.7104 3.77378 16.6734 3.64936C16.6495 3.56916 16.5837 3.5 16.5 3.5Z"
                    fill="white"/>
              <path id="Union_2" fill-rule="evenodd" clip-rule="evenodd"
                    d="M6 7.5C5.95816 7.5 5.92525 7.53458 5.91331 7.57468C5.89479 7.63689 5.85275 7.70808 5.78747 7.87883C5.69767 8.11374 5.56724 8.38496 5.40536 8.63764C5.1924 8.97008 4.86327 9.2501 4.59434 9.42411C4.05144 9.7754 3.71942 9.88726 3.57277 9.91704C3.53297 9.92512 3.5 9.95939 3.5 10C3.5 10.0406 3.53297 10.0749 3.57277 10.083C3.71942 10.1127 4.05144 10.2246 4.59434 10.5759C4.86327 10.7499 5.1924 11.0299 5.40536 11.3624C5.56724 11.615 5.69767 11.8863 5.78747 12.1212C5.85275 12.2919 5.89479 12.3631 5.91331 12.4253C5.92525 12.4654 5.95816 12.5 6 12.5C6.04184 12.5 6.07475 12.4654 6.08669 12.4253C6.10521 12.3631 6.14725 12.2919 6.21253 12.1212C6.30233 11.8863 6.43276 11.615 6.59464 11.3624C6.8076 11.0299 7.13673 10.7499 7.40566 10.5759C7.94856 10.2246 8.28058 10.1127 8.42723 10.083C8.46703 10.0749 8.5 10.0406 8.5 10C8.5 9.95939 8.46703 9.92512 8.42723 9.91704C8.28058 9.88726 7.94856 9.7754 7.40566 9.42411C7.13673 9.2501 6.8076 8.97008 6.59464 8.63764C6.43276 8.38496 6.30233 8.11374 6.21253 7.87883C6.14725 7.70808 6.10521 7.63689 6.08669 7.57468C6.07475 7.53458 6.04184 7.5 6 7.5Z"
                    fill="white"/>
              <path id="Union_3" fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.1667 13.5C11.1053 13.5 11.0571 13.5505 11.042 13.61C11.0163 13.7119 10.9538 13.8242 10.855 14.1061C10.7232 14.482 10.532 14.9159 10.2945 15.3202C9.98218 15.8521 9.49946 16.3002 9.10503 16.5786C8.31985 17.1328 7.83542 17.3146 7.61586 17.3652C7.55255 17.3798 7.5 17.435 7.5 17.5C7.5 17.565 7.55255 17.6202 7.61586 17.6348C7.83542 17.6854 8.31985 17.8672 9.10503 18.4214C9.49946 18.6998 9.98218 19.1479 10.2945 19.6798C10.532 20.0841 10.7232 20.518 10.855 20.8939C10.9538 21.1758 11.0163 21.2881 11.042 21.39C11.0571 21.4495 11.1053 21.5 11.1667 21.5C11.228 21.5 11.2763 21.4495 11.2913 21.39C11.3171 21.2881 11.3796 21.1758 11.4784 20.8939C11.6101 20.518 11.8014 20.0841 12.0388 19.6798C12.3512 19.1479 12.8339 18.6998 13.2283 18.4214C14.0135 17.8672 14.4979 17.6854 14.7175 17.6348C14.7808 17.6202 14.8333 17.565 14.8333 17.5C14.8333 17.435 14.7808 17.3798 14.7175 17.3652C14.4979 17.3146 14.0135 17.1328 13.2283 16.5786C12.8339 16.3002 12.3512 15.8521 12.0388 15.3202C11.8014 14.9159 11.6101 14.482 11.4784 14.1061C11.3796 13.8242 11.3171 13.7119 11.2913 13.61C11.2763 13.5505 11.228 13.5 11.1667 13.5Z"
                    fill="white"/>
          </g>
      </svg>
  );
};
export default AIIcon;
