import React from "react";

const ChevronUpIcon: React.FC = () => {
  return (
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="chevron-up">
              <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd"
                    d="M15.0571 11.1913C15.5778 10.6706 16.4221 10.6706 16.9428 11.1913L24.9428 19.1913C25.4635 19.712 25.4635 20.5562 24.9428 21.0769C24.4221 21.5976 23.5778 21.5976 23.0571 21.0769L16 14.0197L8.94277 21.0769C8.42207 21.5976 7.57785 21.5976 7.05715 21.0769C6.53645 20.5562 6.53645 19.712 7.05715 19.1913L15.0571 11.1913Z"
                    fill="#0D0319"/>
          </g>
      </svg>
  );
};
export default ChevronUpIcon;
