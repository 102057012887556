import React from "react";
import styled from "styled-components";
import BackIcon from "../../components/icon/BackIcon";
import Banner from "./TopBanner";

interface ProductHeadProps {
  isSessionOwnedByUser: boolean;
  handleBackButtonClick: () => void;
}

const ProductHead: React.FC<ProductHeadProps> = ({
  isSessionOwnedByUser,
  handleBackButtonClick,
}) => {
  return (
    <StyledWrapper>
      <Banner />
      <NavWrapper  onClick={handleBackButtonClick}>
        <IconWrapper>
          <BackIcon />
        </IconWrapper>
        <NavName>
          {isSessionOwnedByUser ? "Edit item(s)" : "Copy and edit item(s)"}
        </NavName>
      </NavWrapper>
      <TitleWrapper>Product Insights</TitleWrapper>
    </StyledWrapper>
  );
};

export default ProductHead;
const StyledWrapper = styled.div`
  width: 1410px;
  background: #f7f6f9;
  padding-top: 30px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 9999;
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const NavWrapper = styled("div")`
  &:hover {
    opacity: 0.5;
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const NavName = styled("div")`
  height: 30px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #0d0319;
`;

const TitleWrapper = styled.div`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
