type Disposer = () => void;
type Listener = (evtType: string, payload: unknown) => Promise<void> | void;

class NotificationCenter {
    private _listeners: Map<string, Listener[]>;

    constructor() {
        this._listeners = new Map();
    }

    public addListener(evtType: string, listener: Listener): Disposer {
        if (!this._listeners.has(evtType)) {
            this._listeners.set(evtType, []);
        }

        this._listeners.get(evtType)!.push(listener);
        return () => this.removeListener(evtType, listener);
    }

    public removeListener(evtType: string, listener: Listener) {
        if (!this._listeners.has(evtType)) {
            return;
        }

        const listeners = this._listeners.get(evtType)!;
        const index = listeners.indexOf(listener);
        if (index !== -1) {
            listeners.splice(index, 1);
        }
    }

    public async emit(evtType: string, payload: unknown) {
        if (!this._listeners.has(evtType)) {
            return;
        }

        const listeners = this._listeners.get(evtType)!;
        for (const listener of listeners) {
            await listener(evtType, payload);
        }
    }

    public clear() {
        this._listeners.clear();
    }
}

export const notification = new NotificationCenter();