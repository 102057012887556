import React, { useCallback, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router";
import { routingPaths } from "../../common/constants";
import styled from "styled-components";
import HeaderLogo from "../../components/icon/HeaderLogo";
import {
  createSession,
} from "../../services/user";

const Banner = () => {
    const {
      state: { userId },
      dispatch,
    } = useContext(AppContext);

    const handleHomePageButtonClick = useCallback(() => {
      if (userId) {
        createSession(userId).then((res) => {
          dispatch({ type: "ADD_SESSION_ID", payload: res.id });
        }).finally(() => history.push(routingPaths.home));
      }
    }, [userId]);

    const history = useHistory();

    return (<BannerWrapper>
        <LogoWrapper onClick={handleHomePageButtonClick}>
            <HeaderLogo />
            <LogoText>
                Shop4u.ai
            </LogoText>
        </LogoWrapper>
        <NewSearchButton onClick={handleHomePageButtonClick}>
            <NewSearchText>
                Start a new search
            </NewSearchText>
        </NewSearchButton>
    </BannerWrapper>)
};

export default Banner;

const BannerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
  justify-content: space-between;
  align-self: stretch;
`;

const LogoWrapper = styled("div")`
  display: flex;
  height: 32.081px;
  align-items: center;
  gap: 6.311px;
  cursor: pointer;
`;

const LogoText = styled.div`
  background: var(--brand, linear-gradient(97deg, #8319F5 4.5%, #C24087 127.01%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Work Sans";
  font-size: 27.348px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.094px;
`;

const NewSearchButton = styled("div")`
  display: flex;
  height: 42px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: var(--brand, linear-gradient(97deg, #8319F5 4.5%, #C24087 127.01%));
  cursor: pointer;
`;

const NewSearchText = styled.div`
  color: var(--white, #FFF);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 164.286% */
`;
