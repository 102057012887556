import styled from 'styled-components';

const CompareButton = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    flex: 1 0 auto;
    min-width: 136px;
    min-height: 48px;
    background: linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%);
   
    border-radius: 8px;
    text-align: center;
    color: #fff;
    cursor: pointer;

    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;

    max-height: 55px;
`;
export default CompareButton;
