export const StarGray: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi:star">
      <path
        id="Union"
        d="M12.7056 1.75164C12.573 1.48304 12.2995 1.31299 11.9999 1.31299C11.7004 1.31299 11.4268 1.48304 11.2942 1.75164L8.38714 7.64104L1.88609 8.59126C1.58974 8.63458 1.34366 8.84235 1.25128 9.12725C1.15891 9.41215 1.23625 9.72479 1.4508 9.93376L6.15427 14.5149L5.04424 20.987C4.9936 21.2822 5.11499 21.5806 5.35737 21.7567C5.59974 21.9328 5.92107 21.956 6.18622 21.8165L11.9999 18.7592L17.8136 21.8165C18.0788 21.956 18.4001 21.9328 18.6425 21.7567C18.8848 21.5806 19.0062 21.2822 18.9556 20.987L17.8456 14.5149L22.549 9.93376C22.7636 9.72479 22.8409 9.41215 22.7485 9.12725C22.6562 8.84235 22.4101 8.63458 22.1137 8.59126L15.6127 7.64104L12.7056 1.75164Z"
        fill="rgba(229, 224, 233, 1)"
      />
    </g>
  </svg>
);

export const StarFilled: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi:star">
      <path
        id="Union"
        d="M12.7056 1.75164C12.573 1.48304 12.2995 1.31299 11.9999 1.31299C11.7004 1.31299 11.4268 1.48304 11.2942 1.75164L8.38714 7.64104L1.88609 8.59126C1.58974 8.63458 1.34366 8.84235 1.25128 9.12725C1.15891 9.41215 1.23625 9.72479 1.4508 9.93376L6.15427 14.5149L5.04424 20.987C4.9936 21.2822 5.11499 21.5806 5.35737 21.7567C5.59974 21.9328 5.92107 21.956 6.18622 21.8165L11.9999 18.7592L17.8136 21.8165C18.0788 21.956 18.4001 21.9328 18.6425 21.7567C18.8848 21.5806 19.0062 21.2822 18.9556 20.987L17.8456 14.5149L22.549 9.93376C22.7636 9.72479 22.8409 9.41215 22.7485 9.12725C22.6562 8.84235 22.4101 8.63458 22.1137 8.59126L15.6127 7.64104L12.7056 1.75164Z"
        fill="rgba(13, 3, 25, 1)"
      />
    </g>
  </svg>
);
