import React, { useRef } from "react";
import styled from "styled-components";
import { SelectedProductStatus, SelectedProduct } from "models/pages/products";
import SmallCleanIcon from "../../components/icon/SmallCleanIcon";
import loadingspinner from "../../assets/images/loadingspinner.gif";
import { extractSourceProductIdFromUrl } from "../../utils/appUtils";

interface LoadingSpinnerProps {
  src: string;
}

interface SelectedProductsProps {
  selectedProducts: SelectedProduct[];
  removeSelectedProduct(selectedProducts: SelectedProduct): void;
  selectedProductStatus: SelectedProductStatus;
  isLoadingSelectedProducts: boolean;
}

const SelectedProducts: React.FC<SelectedProductsProps> = ({
  selectedProducts,
  removeSelectedProduct,
  selectedProductStatus,
  isLoadingSelectedProducts,
}) => {
  const compares = useRef(new Array(4).fill({}));
  return (
    <StyledWrapper>
      {isLoadingSelectedProducts ? (
        <SelectedProductLoadingIndicator>
          <SelectedProductLoadingSpinner src={loadingspinner} />
          <SelectedProductLoadingText>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Loading the items you've added...
          </SelectedProductLoadingText>
        </SelectedProductLoadingIndicator>
      ) : selectedProducts.length > 0 ? (
        <>
          {compares.current.map((_, index) => (
            <CompareWrapper key={index}>
              {selectedProducts && selectedProducts[index] ? (
                <>
                  {selectedProducts[index].url &&
                    ["ADDING", "REMOVING"].includes(
                      selectedProductStatus[
                        extractSourceProductIdFromUrl(
                          selectedProducts[index].url
                        )
                      ]
                    ) && (
                      <LoadingOverlayWrapper>
                        <LoadingOverlay />
                        <LoadingSpinnerInLoadingOverlay src={loadingspinner} />
                      </LoadingOverlayWrapper>
                    )}
                  <RemoveWrapper
                    onClick={() =>
                      removeSelectedProduct(selectedProducts[index])
                    }
                  >
                    <SmallCleanIcon />
                  </RemoveWrapper>
                  <LogoWrapper>
                    <StyledImage
                      src={selectedProducts[index].image_url}
                      alt={selectedProducts[index].name}
                      maxWidth="95px"
                      maxHeight="95px"
                      objectFit="fill"
                    />
                  </LogoWrapper>
                  <ProductName>{selectedProducts[index].name}</ProductName>
                </>
              ) : (
                <PendingCard />
              )}
            </CompareWrapper>
          ))}
        </>
      ) : (
        <NoItemWrapper>
          <PlaceholderIconWrapper>
            <LeftRectangle />
            <Seperator />
            <RightRectangle />
          </PlaceholderIconWrapper>
          <PlaceholderText>No items to compare</PlaceholderText>
        </NoItemWrapper>
      )}
    </StyledWrapper>
  );
};

export default SelectedProducts;

const StyledWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--corner_card, 16px);
`;

const CompareWrapper = styled("div")`
  display: flex;
  width: 140px;
  height: 140px;
  padding: 5.577px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5.577px;
  border-radius: 8px;
  border: 1px solid var(--gray20, #e5e0e9);
  background: var(--white, #fff);
  position: relative;
`;

const PendingCard = styled.div`
  width: 140px;
  height: 140px;
  padding: 6px;
  gap: 6px;

  border-radius: 8px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-inline: 8px;

  border: 2px dashed #e5e0e9;
`;

const RemoveWrapper = styled("div")`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 5.577px;
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 200px;
  background: rgba(229, 224, 233, 0.8);
  cursor: pointer;
`;

const LogoWrapper = styled("div")`
  display: flex;
  width: 94.459px;
  height: 94.459px;
  padding: 5.51px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 11.153px;
  background: var(--white, #fff);
`;

const ProductName = styled.div`
  display: block;
  width: 100%;
  padding: 5px 8px;
  position: absolute;
  bottom: 0px;
  background: rgba(229, 224, 233, 0.8);
  overflow: hidden;
  color: var(--gray70, #74697b);
  text-overflow: ellipsis;
  box-sizing: border-box;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  white-space: nowrap;
`;

interface StyledImage extends React.AllHTMLAttributes<HTMLImageElement> {
  src: string | undefined;
  alt: string;
  maxWidth: string;
  maxHeight: string;
  objectFit: string;
}

const StyledImage = styled.img<StyledImage>`
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  object-fit: ${({ objectFit }) => objectFit};
`;

const LoadingOverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const LoadingOverlay = styled.div`
  background: white;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

const LoadingSpinnerInLoadingOverlay = styled.img<
  React.AllHTMLAttributes<HTMLImageElement>
>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
`;

const SelectedProductLoadingIndicator = styled.div`
  display: flex;
  height: 153px;
  align-items: center;
  gap: 8px;
`;

const SelectedProductLoadingSpinner = styled.img<
  Pick<LoadingSpinnerProps, "src">
>`
  width: 24px;
  height: 24px;
`;

const SelectedProductLoadingText = styled.div`
  overflow: hidden;
  color: #a79dae;
  text-align: right;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;

const NoItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 153px;
  margin-top: 40px;
`;

const PlaceholderIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--corner_card, 16px);
`;

const LeftRectangle = styled.div`
  width: 36px;
  height: 80px;
  border-radius: 8px;
  border: 2px solid #a79dae;
`;

const Seperator = styled.div`
  width: 2px;
  height: 73px;
  background: #e5e0e9;
`;

const RightRectangle = styled.div`
  width: 36px;
  height: 80px;
  border-radius: 8px;
  border: 2px dashed #a79dae;
`;

const PlaceholderText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #a79dae;
  text-align: right;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;
