import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { capitalize } from "lodash";

import ProductHead from "./ProductHead";
import {
  Thumbnail,
  ProductBuyingOption,
  GetOverviewResponse,
  GetReviewsResponse,
  GetSepecificationsResponse,
  GetBuyingOptionResponse,
  SendChatResponse,
  GetSessionImageResponse,
  GetCustomSections,
  ChatUpdateSectionResponse,
  ChatAddProductResponse,
  ChatGenerateSectionResponse,
  ChatDeleteProductResponse,
} from "models/services/api";
import {
  Overview,
  ProductBuyingOptionInfo,
  Product,
} from "models/pages/products";
import { CommonSectionInfo } from "models/pages/products";
import PreviewSection from "./PreviewSection";
import ProductSection from "./ProductSection";
import OverviewCard from "./OverviewSection";
import BuyingOptionSection from "./BuyingOptionSection";
import SpecificationSection from "./SpecificationSection";
import ReviewSection from "./ReviewSection";
import CompactHeader from "./CompactHeader";
import CustomSection from "./CustomSection";
import Prompt from "components/prompt/Prompt";
import {
  getSessionImage,
  getOverview,
  getReviews,
  getSpecifications,
  getBuyingOptions,
  addProduct,
  getCustomSections,
  removeProductFromSession,
} from "../../services/product";
import { routingPaths } from "../../common/constants";
import { createSession, createUserByName } from "../../services/user";
import { AppContext } from "../../context/AppContext";
import { headerTransformAnchor } from "../../common/constants";
import { replaceNewline, restoreNewline } from "../../utils/appUtils";
import usePolling from "hooks/polling";

interface ProductPageProps {
  sessionId?: string;
}

export const ProductPage: React.FC<ProductPageProps> = (
  props: ProductPageProps
) => {
  const {
    state: { userName, userId, sessionIds },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();
  const sessionIdFromParams = useParams<{ sessionId: string }>().sessionId;
  const sessionId = props.sessionId || sessionIdFromParams;
  const [thumbnails, setThumbnails] = useState<Thumbnail[]>([]);
  const [overviews, setOverviews] = useState<Overview[]>([]);
  const [reviews, setReviews] = useState<CommonSectionInfo[]>([]);
  const [specifications, setSpecifications] = useState<CommonSectionInfo[]>([]);
  const [productBuyingOptions, setProductBuyingOptions] = useState<
    ProductBuyingOptionInfo[]
  >([]);
  const [shouldShowHeader, setShouldShowHeader] = useState<boolean>(true);

  const [shouldPollThumbnails, setShouldPollThumbnails] =
    useState<boolean>(true);
  const { data: thumbnailsResponse } = usePolling<GetSessionImageResponse>(
    getSessionImage,
    shouldPollThumbnails,
    [sessionId],
    5000
  );

  const [shouldPollOverviews, setShouldPollOverviews] =
    useState<boolean>(false);
  const { data: overviewResponse } = usePolling<GetOverviewResponse>(
    getOverview,
    shouldPollOverviews,
    [sessionId],
    5000
  );

  const [shouldPollReviews, setShouldPollReviews] = useState<boolean>(false);
  const { data: reviewResponse } = usePolling<GetReviewsResponse>(
    getReviews,
    shouldPollReviews,
    [sessionId],
    5000
  );

  const [shouldPollSpecifications, setShouldPollSpecifications] =
    useState<boolean>(false);
  const { data: specificationResponse } =
    usePolling<GetSepecificationsResponse>(
      getSpecifications,
      shouldPollSpecifications,
      [sessionId],
      5000
    );

  const [shouldPollBuyingOptions, setShouldPollBuyingOptions] =
    useState<boolean>(false);
  const { data: buyingOptionResponse } = usePolling<GetBuyingOptionResponse>(
    getBuyingOptions,
    shouldPollBuyingOptions,
    [sessionId],
    5000
  );

  const [shouldPollCustomSections, setShouldPollCustomSections] =
    useState<boolean>(false);
  const { data: customSectionsResponse } = usePolling<GetCustomSections>(
    getCustomSections,
    shouldPollCustomSections,
    [sessionId],
    5000
  );

  const [isLoadingThumbnails, setIsLoadingThumbnails] = useState<boolean>(true);
  const [isLoadingOverviews, setIsLoadingOverviews] = useState<boolean>(true);
  const [isLoadingReviews, setIsLoadingReviews] = useState<boolean>(true);
  const [isLoadingSpecifications, setIsLoadingSpecifications] =
    useState<boolean>(true);
  const [isLoadingProductBuyingOptions, setIsLoadingProductBuyingOptions] =
    useState<boolean>(true);
  const [isUserOwnedSession, setIsUserOwnedSession] = useState<boolean>(false);
  const [customSectionQueryCount, setCustomSectionQueryCount] =
    useState<number>(0);

  const [products] = useState<Product[]>([]);
  const [additionalSections, setAdditionalSections] = useState<
    { name: string; sectionInfos: CommonSectionInfo[] }[]
  >([]);

  const handleScroll = () => {
    if (window.scrollY > headerTransformAnchor) {
      setShouldShowHeader(false);
    } else {
      setShouldShowHeader(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (thumbnails.length > 0) {
      if (!userId) {
        createUserByName(userName).then((createUserRes) => {
          dispatch({ type: "SET_USER_ID", payload: createUserRes.id });
          createSession(createUserRes.id).then(async (createSessionRes) => {
            dispatch({
              type: "ADD_SESSION_ID",
              payload: createSessionRes.id,
            });
            await Promise.all(
              thumbnails.map((thumbnail) =>
                addProduct(createSessionRes.id, thumbnail.url)
              )
            );
            setIsUserOwnedSession(true);
            history.push(`/product/${createSessionRes.id}`);
          });
        });
      } else if (!sessionIds || !sessionIds.includes(sessionId)) {
        createSession(userId).then(async (createSessionRes) => {
          dispatch({ type: "ADD_SESSION_ID", payload: createSessionRes.id });
          await Promise.all(
            thumbnails.map((thumbnail) =>
              addProduct(createSessionRes.id, thumbnail.url)
            )
          );
          setIsUserOwnedSession(true);
          history.push(`/product/${createSessionRes.id}`);
        });
      } else {
        setIsUserOwnedSession(true);
      }
    }
  }, [thumbnails]);

  const reloadThumbnails = useCallback(() => {
    setShouldPollThumbnails(true);
    setIsLoadingThumbnails(true);
    setIsLoadingOverviews(true);
    setIsLoadingProductBuyingOptions(true);
    setIsLoadingReviews(true);
    setIsLoadingSpecifications(true);
  }, [
    setShouldPollThumbnails,
    setIsLoadingThumbnails,
    setIsLoadingOverviews,
    setIsLoadingProductBuyingOptions,
    setIsLoadingReviews,
    setIsLoadingSpecifications,
  ]);

  const handlePromptResponse = (res: SendChatResponse) => {
    if (res.type === "Update Section") {
      const convertedRes = res as ChatUpdateSectionResponse;
      if (convertedRes.name === "Overview") {
        setShouldPollOverviews(true);
        const section = document.getElementById("overview");
        section!.scrollIntoView({ behavior: "smooth" });
      } else if (convertedRes.name === "Specifications") {
        setShouldPollSpecifications(true);
        const section = document.getElementById("specifications");
        section!.scrollIntoView({ behavior: "smooth" });
      } else if (convertedRes.name === "Ratings & Reviews") {
        setShouldPollReviews(true);
        const section = document.getElementById("ratingsandreviews");
        section!.scrollIntoView({ behavior: "smooth" });
      } else if (convertedRes.name === "Buying Options") {
        setShouldPollBuyingOptions(true);
        const section = document.getElementById("buyingoptions");
        section!.scrollIntoView({ behavior: "smooth" });
      } else {
        setShouldPollCustomSections(true);
        const section = document.getElementById("customsection");
        section!.scrollIntoView({ behavior: "smooth" });
      }
    } else if (res.type === "Generate Section") {
      const convertedRes = res as ChatGenerateSectionResponse;
      setAdditionalSections((additionalSections) => [
        ...additionalSections,
        {
          name: convertedRes.name,
          sectionInfos: thumbnails.map((thumbnail) => {
            const content =
              convertedRes.product_id_to_content![thumbnail.product_id];
            return {
              highlights: content.highlights,
              content: content.text_content,
              type: "additional",
              productId: thumbnail.product_id,
              isLoading: false,
            };
          }),
        },
      ]);
      const section = document.getElementById(convertedRes.name);
      section?.scrollIntoView({ behavior: "smooth" });
    } else if (res.type === "Add Product") {
      const convertedRes = res as ChatAddProductResponse;
      if (convertedRes.product_url) {
        addProduct(sessionId, convertedRes.product_url).then(() => {
          reloadThumbnails();
        });
      }
    } else if (res.type === "Delete Product") {
      const convertedRes = res as ChatDeleteProductResponse;
      if (convertedRes.product_id) {
        removeProductFromSession(sessionId, convertedRes.product_id).then(
          () => {
            reloadThumbnails();
          }
        );
      }
    }
  };

  useEffect(() => {
    if (thumbnails && thumbnails.length > 0 && isUserOwnedSession) {
      setShouldPollOverviews(true);
      setShouldPollBuyingOptions(true);
      setShouldPollReviews(true);
      setShouldPollSpecifications(true);
      setShouldPollCustomSections(true);
    }
  }, [
    thumbnails,
    isUserOwnedSession,
    setShouldPollOverviews,
    setShouldPollBuyingOptions,
    setShouldPollReviews,
    setShouldPollSpecifications,
    setShouldPollCustomSections,
  ]);

  useEffect(() => {
    if (thumbnailsResponse) {
      setThumbnails(thumbnailsResponse.thumbnails);
      setIsLoadingThumbnails(false);

      document.title =
        thumbnailsResponse.title ??
        thumbnailsResponse.thumbnails.reduce(
          (accu, curr) => `${accu} ${curr} `,
          "Product Comparision for"
        );

      if (
        !thumbnailsResponse.thumbnails.find(
          (thumbnail: Thumbnail) =>
            !thumbnail.image_url ||
            !thumbnail.name ||
            !thumbnail.url ||
            !thumbnail.product_id
        )
      ) {
        setShouldPollThumbnails(false);
      }
    }
  }, [thumbnailsResponse]);

  useEffect(() => {
    if (overviewResponse && thumbnails.length > 0) {
      setOverviews(
        thumbnails.map((thumbnail) => {
          try {
            const overview = overviewResponse[thumbnail.product_id];
            return {
              isLoading: overview ? false : true,
              rating: overview ? overview.rating : 0,
              content: overview
                ? JSON.parse(overview.content)
                : { highlights: [], textcontent: "" },
            };
          } catch {
            return {
              isLoading: true,
              rating: 0,
              content: { highlights: [], textcontent: "" },
            };
          }
        })
      );
      setIsLoadingOverviews(false);

      if (
        thumbnails.every((thumbnail) =>
          Object.keys(overviewResponse).includes(thumbnail.product_id)
        )
      ) {
        setShouldPollOverviews(false);
      }
    }
  }, [overviewResponse, thumbnails]);

  useEffect(() => {
    if (reviewResponse && thumbnails.length > 0) {
      setReviews(
        thumbnails.map((thumbnail) => {
          const review = reviewResponse[thumbnail.product_id];

          if (review) {
            const parsedReview = JSON.parse(replaceNewline(review));
            return {
              highlights: parsedReview.highlights
                ? parsedReview.highlights
                : [],
              content: parsedReview.textcontent
                ? restoreNewline(parsedReview.textcontent)
                : "",
              type: "ratingsandreviews",
              isLoading: parsedReview ? false : true,
              productId: thumbnail.product_id,
            };
          } else {
            return {
              highlights: [],
              content: "",
              type: "ratingsandreviews",
              isLoading: true,
              productId: thumbnail.product_id,
            };
          }
        })
      );
      setIsLoadingReviews(false);

      if (
        thumbnails.every((thumnail) =>
          Object.keys(reviewResponse).includes(thumnail.product_id)
        )
      ) {
        setShouldPollReviews(false);
      }
    }
  }, [reviewResponse, thumbnails]);

  useEffect(() => {
    if (specificationResponse && thumbnails.length > 0) {
      setSpecifications(
        thumbnails.map((thumbnail) => {
          const content = specificationResponse[thumbnail.product_id];
          return {
            isLoading: content ? false : true,
            content: content
              ? restoreNewline(JSON.parse(replaceNewline(content)).textcontent)
              : "",
            highlights: [],
            productId: thumbnail.product_id,
            type: "specifications",
          };
        })
      );
      setIsLoadingSpecifications(false);

      if (
        thumbnails.every((thumbnail) =>
          Object.keys(specificationResponse).includes(thumbnail.product_id)
        )
      ) {
        setShouldPollSpecifications(false);
      }
    }
  }, [specificationResponse, thumbnails]);

  useEffect(() => {
    if (buyingOptionResponse && thumbnails.length > 0) {
      setProductBuyingOptions(
        thumbnails.map((thumbnail) => {
          const pbo: ProductBuyingOption = Object.values(
            buyingOptionResponse
          ).find((p) => p.product_id === thumbnail.product_id);
          if (pbo) {
            return {
              productId: pbo.product_id,
              isLoading: false,
              buyingOptions: pbo.buying_options.map((buying_option) => ({
                platformName: buying_option.platform_name,
                delivery: buying_option.delivery,
                price: buying_option.price,
                link: buying_option.link,
              })),
            };
          } else {
            return {
              productId: thumbnail.product_id,
              isLoading: true,
              buyingOptions: [],
            };
          }
        })
      );
      setIsLoadingProductBuyingOptions(false);

      if (
        thumbnails.every(
          (thumbnail) =>
            Object.values(buyingOptionResponse).find(
              (b) => b.product_id === thumbnail.product_id
            ) &&
            Object.values(buyingOptionResponse).find(
              (b) => b.product_id === thumbnail.product_id
            ).buying_options.length > 0
        )
      ) {
        setShouldPollBuyingOptions(false);
      }
    }
  }, [buyingOptionResponse, thumbnails]);

  useEffect(() => {
    if (customSectionsResponse && thumbnails.length > 0) {
      setAdditionalSections(
        Object.keys(customSectionsResponse).map((name) => ({
          name,
          sectionInfos: thumbnails.map((thumbnail) => {
            const content = customSectionsResponse[name][thumbnail.product_id];
            return {
              isLoading: content ? false : true,
              content,
              highlights: [],
              productId: thumbnail.product_id,
              type: "additional",
            };
          }),
        }))
      );

      if (
        Object.values(customSectionsResponse).every((res) => {
          thumbnails.every((thumbnail) =>
            Object.keys(res).includes(thumbnail.product_id)
          );
        }) ||
        customSectionQueryCount >= 10
      ) {
        setShouldPollCustomSections(false);
      }

      setCustomSectionQueryCount(
        (customSectionQueryCount) => customSectionQueryCount + 1
      );
    }
  }, [customSectionsResponse, thumbnails, setCustomSectionQueryCount]);

  const handleBackButtonClick = () => history.push(routingPaths.home);

  return (
    <StyledWrapper>
      <HomeWrapper>
        {shouldShowHeader ? (
          <ProductHead
            handleBackButtonClick={handleBackButtonClick}
            isSessionOwnedByUser={
              !!sessionIds && sessionIds.includes(sessionId)
            }
          />
        ) : (
          <CompactHeader thumbnails={thumbnails} />
        )}
        <PreviewSection
          thumbnails={thumbnails}
          isLoading={isLoadingThumbnails || !isUserOwnedSession}
        />
        <ProductSection
          title="Overview"
          size={Object.keys(overviews).length}
          sessionId={sessionId}
          sectionName={"Overview"}
          id={"overview"}
        >
          <OverviewCard
            isLoadingThumbnail={isLoadingOverviews}
            overviews={Object.values(overviews)}
            thumbnails={thumbnails}
          />
        </ProductSection>
        <ProductSection
          title="Buying Options"
          size={products.length}
          sessionId={sessionId}
          sectionName={"Buying Options"}
          id={"buyingoptions"}
        >
          <BuyingOptionSection
            isLoadingThumbnail={isLoadingProductBuyingOptions}
            productBuyingOptions={productBuyingOptions}
            thumbnails={thumbnails}
          />
        </ProductSection>
        <ProductSection
          title="Specifications"
          size={specifications.length}
          sessionId={sessionId}
          sectionName={"Specifications"}
          id={"specifications"}
        >
          <SpecificationSection
            isLoadingThumbnail={isLoadingSpecifications}
            specifications={specifications}
            thumbnails={thumbnails}
          />
        </ProductSection>
        <ProductSection
          title="Ratings and Reviews"
          size={reviews.length}
          sessionId={sessionId}
          sectionName={"Ratings & Reviews"}
          id={"ratingsandreviews"}
        >
          <ReviewSection
            isLoadingThumbnail={isLoadingReviews}
            reviews={reviews}
            thumbnails={thumbnails}
          />
        </ProductSection>
        <CustomSectionWrapper id={"customsection"}>
          {additionalSections.map((additionalSection, index) => (
            <ProductSection
              title={capitalize(additionalSection.name)}
              size={additionalSection.sectionInfos.length}
              sessionId={sessionId}
              sectionName={additionalSection.name}
              id={additionalSection.name}
              key={index}
            >
              <CustomSection
                sectionName={additionalSection.name}
                sectionInfos={additionalSection.sectionInfos}
                isLoadingThumbnail={isLoadingThumbnails}
                thumbnails={thumbnails}
              ></CustomSection>
            </ProductSection>
          ))}
        </CustomSectionWrapper>
        <PromptWrapper>
          <Prompt
            productUrls={thumbnails.map((thumbnail) => thumbnail.url)}
            sessionId={sessionId}
            onSearch={handlePromptResponse}
            onAddProduct={reloadThumbnails}
          ></Prompt>
        </PromptWrapper>
      </HomeWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: auto;
  height: 100%;
  background: #f7f6f9;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HomeWrapper = styled.div`
  width: 1400px;
  height: 100%;
  background: #f7f6f9;
`;

const PromptWrapper = styled.div`
  position: fixed;
  right: 36px;
  bottom: 36px;
  z-index: 999;
`;

const CustomSectionWrapper = styled.div<
  React.AllHTMLAttributes<HTMLDivElement>
>``;
