import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Dot from "../icon/Dot";

const SuggestionItem = (props: { link: string, productNames: string[]}) => {
    const history = useHistory();
    return <div onClick={() => {
      let link = props.link;
      if (props.link.startsWith('https://www.shop4u.ai')) {
        link = props.link.replace('https://www.shop4u.ai', '');
      }
      history.push(link);
    }}>
        <SuggestionItemWrapper>
            {props.productNames.map((productName, index) => <><SuggestionItemText>{productName}</SuggestionItemText>{index < props.productNames.length - 1 ? <Dot /> : ''}</>)}
        </SuggestionItemWrapper>
    </div>
};

const SuggestionItemText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--gray70, #74697B);
  text-align: right;
  text-overflow: ellipsis;

  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const SuggestionItemWrapper = styled.div`
  display: flex;
  height: 26px;
  padding: 2px 12px;
  gap: 12px;
  align-items: center;
  border-radius: 200px;
  border: 1px solid var(--gray20, #E5E0E9);
  cursor: pointer;
`;

export default SuggestionItem;