export const extractSourceProductIdFromUrl = (url: string) =>
  url.split("product_id=")[1];

export const getNumberFromPrice = (price: string) =>
  Number(price.replace(/[^0-9.]/g, ""));

export const replaceNewline = (text: string) => {
  return text.replace(/\n/g, "[newline]");
};

export const restoreNewline = (text: string) => {
  return text.replace(/\[newline\]/g, "\n");
};
