import mixpanel, { Dict } from 'mixpanel-browser';

import CONFIG from '../config/config';

export const mixPanelToken = CONFIG.MIXPANEL_PROJECT_TOKEN;

const prefix = 'shop4u_ai_web_';
const htmlStartLoadingTimeItemName = '__html_start_loading';

// const timeSinceHtmlStartLoading = (): number | null => {
//   const htmlStartLoadingTime = window.sessionStorage.getItem(htmlStartLoadingTimeItemName);
//   return htmlStartLoadingTime ? Date.now() - parseFloat(htmlStartLoadingTime) : null;
// };

export const clearHtmlStartLoadingTime = (): void => {
  window.sessionStorage.removeItem(htmlStartLoadingTimeItemName);
};

export const mpTrack = (eventName: string, props?: Dict): void => mixpanel.track(`${prefix}${eventName}`, props);
export const mpTrackEnterPage = (pageName: string): void => mpTrack('enter_page', { pageName });
export const mpTrackAction = (name: string, params?: { [key: string]: string | boolean | number | undefined }): void =>
  mpTrack('action', { name, ...params });
export const mpTrackError = (name: string, params?: { [key: string]: string | boolean | number | undefined }): void =>
  mpTrack('error', { name, ...params });

const init = () => {
  mixpanel.init(mixPanelToken, { debug: CONFIG.MIXPANEL_PROJECT_DEBUG });
  // mpTrack('page_code_loaded', { load_time: timeSinceHtmlStartLoading() });
};

init();
