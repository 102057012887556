import React from "react";

const ThumbsDownIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="fi:thumbs-down">
        <path
          id="Vector"
          d="M17 2.63415H19.67C20.236 2.62414 20.7859 2.82229 21.2154 3.19097C21.645 3.55965 21.9242 4.07321 22 4.63415V11.6342C21.9242 12.1951 21.645 12.7086 21.2154 13.0773C20.7859 13.446 20.236 13.6442 19.67 13.6342H17M10 15.6342V19.6342C10 20.4298 10.3161 21.1929 10.8787 21.7555C11.4413 22.3181 12.2044 22.6342 13 22.6342L17 13.6342V2.63415H5.72003C5.2377 2.6287 4.76965 2.79775 4.40212 3.11014C4.0346 3.42254 3.79235 3.85724 3.72003 4.33415L2.34003 13.3342C2.29652 13.6208 2.31586 13.9135 2.39669 14.1919C2.47753 14.4703 2.61793 14.7279 2.80817 14.9466C2.99842 15.1654 3.23395 15.3402 3.49846 15.4589C3.76297 15.5776 4.05012 15.6374 4.34003 15.6342H10Z"
          stroke="#74697B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export default ThumbsDownIcon;
