import React, {
  ReactNode,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import styled, { keyframes } from "styled-components";
import { truncate } from "lodash";
import Rating from "react-rating";
import {
  SelectedProductStatus,
  ProductStatusValues,
} from "models/pages/products";
import { MatchingProduct } from "models/services/api";
import { Image, List, ListItem } from "@chakra-ui/react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { StarFilled, StarGray } from "../../components/icon/Star";
import PlusIcon from "../../components/icon/PlusIcon";
import CheckedIcon from "../../components/icon/CheckedIcon";
import loadingspinner from "../../assets/images/loadingspinner.gif";
import "./styles.css";

const constants = {
  MAX_PRODUCT_NAME_WIDTH: 70,
  PLATFORMS: ["Amazon", "Google", "Walmart"],
};

interface LoadingSpinnerProps {
  src: string;
}

interface StyledWrapperProps {
  isLoading: boolean;
}

interface CompareWrapperProps extends React.AllHTMLAttributes<HTMLDivElement> {
  status: ProductStatusValues | undefined;
  onClick?: () => void;
}

interface PopperProductProps {
  matchingProducts: MatchingProduct[];
  selectProductToCompare(matchingProduct: MatchingProduct): void;
  matchingProductStatus: SelectedProductStatus;
  isLoadingList: boolean;
  handleClickOutside: () => void;
  url: string | undefined;
}

const PopperProduct: React.FC<PopperProductProps> = ({
  matchingProducts,
  selectProductToCompare,
  matchingProductStatus,
  isLoadingList,
  handleClickOutside,
  url,
}) => {
  const popperProductRef = useRef<HTMLDivElement>(null);
  const [loadingTextIndex] = useState(0);
  const loadingTextIndexRef = useRef(loadingTextIndex);
  const [loadingTexts] = useState<string[]>(
    constants.PLATFORMS.map((platform) => `AI is analyzing ${platform}...`)
  );
  const loadingTextsRef = useRef(loadingTexts);
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    if (url) {
      const parsedUrl = new URL(url);
      loadingTextsRef.current = [`AI is analyzing ${parsedUrl.hostname}`];
    } else {
      loadingTextsRef.current = constants.PLATFORMS.map(
        (platform) => `AI is analyzing ${platform}...`
      );
    }
  }, [url, loadingTextsRef]);

  const loadingAnimationHandler = useCallback(() => {
    setTimeout(() => {
      setIsVisible(false);
    }, 1500);
    setTimeout(() => {
      loadingTextIndexRef.current =
        (loadingTextIndexRef.current + 1) % loadingTextsRef.current.length;
    }, 1900);
  }, [loadingTexts, loadingTextIndexRef]);

  useEffect(() => {
    if (isLoadingList) {
      loadingAnimationHandler();
      const intervalId = setInterval(() => {
        setIsVisible(true);
        loadingAnimationHandler();
      }, 2000);
      return () => {
        setIsVisible(true);
        clearInterval(intervalId);
        loadingTextIndexRef.current = -1;
      };
    }
  }, [isLoadingList]);

  const _handleClickOutside = (event: MouseEvent) => {
    if (
      popperProductRef.current &&
      !popperProductRef.current.contains(event.target as Node)
    ) {
      handleClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", _handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", _handleClickOutside);
    };
  }, []);

  return (
    <StyledWrapper isLoading={isLoadingList} ref={popperProductRef}>
      {isLoadingList ? (
        <LoadingText isVisible={isVisible}>
          {loadingTextsRef.current[loadingTextIndexRef.current]}
        </LoadingText>
      ) : (
        <List spacing={4} style={{ paddingLeft: 0 }}>
          <TransitionGroup>
            {matchingProducts.map((matchingProduct: MatchingProduct) => (
              <CSSTransition
                key={matchingProduct.product_id}
                timeout={300}
                classNames="fade"
              >
                <ListItem>
                  <ItemWrapper>
                    <ProductWrapper>
                      <LogoWrapper>
                        <Image
                          src={matchingProduct.image_url}
                          alt={matchingProduct.name}
                          maxWidth="32px"
                          maxHeight="32px"
                          objectFit="fill"
                        />
                      </LogoWrapper>
                      <InfoWrapper>
                        <ProductName>
                          {truncate(matchingProduct.name, {
                            length: constants.MAX_PRODUCT_NAME_WIDTH,
                          })}
                        </ProductName>
                        <RatingAndPriceWrapper>
                          <Rating
                            initialRating={matchingProduct.rating}
                            readonly
                            emptySymbol={<StarGray />}
                            fullSymbol={<StarFilled />}
                            className={"custom-rating"}
                          />

                          <PriceWrapper>
                            From {matchingProduct.price}
                          </PriceWrapper>
                        </RatingAndPriceWrapper>
                      </InfoWrapper>
                    </ProductWrapper>
                    <CompareWrapper
                      status={matchingProductStatus[matchingProduct.product_id]}
                      onClick={() => {
                        if (
                          !["ADDING", "ADDED"].includes(
                            matchingProductStatus[matchingProduct.product_id]
                          )
                        ) {
                          selectProductToCompare(matchingProduct);
                        }
                      }}
                    >
                      {matchingProductStatus[matchingProduct.product_id] ===
                        "ADDING" && (
                        <LoadingWrapper>
                          <LoadingOverlay />
                          <ItemLoadingSpinner src={loadingspinner} />
                        </LoadingWrapper>
                      )}
                      <CompareButton>
                        {matchingProductStatus[matchingProduct.product_id] ===
                        "ADDED" ? (
                          <CheckedIcon />
                        ) : (
                          <PlusIcon />
                        )}
                        <PositionWrapper>
                          {matchingProductStatus[matchingProduct.product_id] ===
                          "ADDED"
                            ? "Added"
                            : "Compare"}
                        </PositionWrapper>
                      </CompareButton>
                    </CompareWrapper>
                  </ItemWrapper>
                </ListItem>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </List>
      )}
    </StyledWrapper>
  );
};
export default PopperProduct;

const StyledWrapper = styled("div")<StyledWrapperProps>`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  align-items: ${({ isLoading }) => (isLoading ? "center" : "flex-start")};
  justify-content: center;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(234, 234, 234, 1);
    border-radius: 4px;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  padding: 0 20px;
  margin-bottom: 20px;
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--corner_card, 16px);
  flex: 1 0 0;
`;

const LogoWrapper = styled("div")`
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 7.692px;
  border: 1px solid var(--gray20, #e5e0e9);
  box-sizing: border-box;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const ProductName = styled.div`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  align-self: stretch;
`;

const RatingAndPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const PriceWrapper = styled.div`
  color: var(--gray70, #74697b);

  /* body1 */
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
`;

const CompareWrapper = styled.div<CompareWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 12px 20px 12px var(--corner_card, 16px);
  position: relative;
  cursor: ${(props) => (props.status !== undefined ? "auto" : "pointer")};
  transition: opacity 0.1s ease;

  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
  &:hover {
    opacity: ${(props) =>
      props.status && ["ADDED", "ADDING"].includes(props.status) ? 0.6 : 0.9};
  }
  &:active {
    opacity: ${(props) =>
      props.status && ["ADDED", "ADDING"].includes(props.status) ? 0.6 : 0.8};
  }
  opacity: ${(props) =>
    props.status && ["ADDED", "ADDING"].includes(props.status) ? 0.6 : 1};
`;

const LoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingOverlay = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0.3;
`;

const CompareButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const PositionWrapper = styled.span`
  color: var(--white, #fff);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
`;

const ItemLoadingSpinner = styled.img<Pick<LoadingSpinnerProps, "src">>`
  height: 32px;
  width: 32px;
  position: absolute;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(-20px);

  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
        transform: translateY(-20px);

  }
  100% {
    opacity: 0;
        transform: translateY(-40px);

  }
`;

const LoadingText = styled.div<{ isVisible: boolean; children: ReactNode }>`
  position: absolute;
  opacity: 0;
  animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 0.5s ease-in-out
    forwards;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--gray50, #a79dae);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;
