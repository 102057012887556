import styled from "styled-components";
import React, { useEffect } from "react";
import CloseWhiteIcon from "../../components/icon/CloseWhiteIcon";

interface MsgProps {
  closeMsgShow: () => void;
}
const MsgAlert: React.FC<MsgProps> = ({ closeMsgShow }) => {
  useEffect(() => {
    const timeoutId = setTimeout(closeMsgShow, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <Msg>
        <div onClick={closeMsgShow}>
          <CloseMsgForm>
            <CloseWhiteIcon />
          </CloseMsgForm>
        </div>
        Thank you!
        <p>You feedback has been sent.</p>
      </Msg>
    </>
  );
};

export default MsgAlert;

const CloseMsgForm = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const Msg = styled.div`
  position: fixed;
  bottom: 50px;
  left: 50px;
  background: rgb(11, 3, 24);
  color: white;
  font-size: 20px;
  padding: 15px;
  padding-right: 50px;
  border-radius: 10px;

  & p {
    color: rgb(157, 151, 163);
    font-size: 12px;
    margin: 5px 0;
  }
`;
