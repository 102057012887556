import React from "react";
import Exit from "../icon/Exit";
import Google from "../icon/Google";
import Facebook from "../icon/Facebook";
import styled from "styled-components";
import { googleSignin, facebookSignin} from '../../services/integrations';
import useFacebookSDK from '../../hooks/facebook';

type SignInProps = {
    show: boolean;
    onClose: () => void;
};

const SignIn = (props: SignInProps) => {
  if (!props.show) return null;
  useFacebookSDK();
  return (
    <SignInWrapper>
      <HeaderWrapper>
        <HeaderContentWrapper>
          <HeaderSignInText>Sign in</HeaderSignInText>
          <HeaderExitWrapper>
            <HeaderExitIconWrapper onClick={props.onClose}>
              <Exit />
            </HeaderExitIconWrapper>
          </HeaderExitWrapper>
        </HeaderContentWrapper>
      </HeaderWrapper>
      <SignInBodyWrapper>
        <SignInBodyContent>
          <GoogleSignInWrapper onClick={googleSignin}>
            <GoogleSignInIcon>
              <Google />
            </GoogleSignInIcon>
            <GoogleSignInText>Sign in with Google</GoogleSignInText>
          </GoogleSignInWrapper>
          <FacebookSignInWrapper onClick={facebookSignin}>
            <FacebookSignInIcon>
              <Facebook />
            </FacebookSignInIcon>
            <FacebookSignInText>Sign in with Facebook</FacebookSignInText>
          </FacebookSignInWrapper>
        </SignInBodyContent>
      </SignInBodyWrapper>
    </ SignInWrapper>
  );
};

export default SignIn;

const SignInWrapper = styled.div`
    position: fixed;
    top: 25%;
    left: 40%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 999;
    border-radius: 16px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const HeaderWrapper = styled.div`
  display: flex;
  padding: var(--corner_card, 16px);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--purple-gray20, #e5e0e9);
  background: #fff;
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const HeaderSignInText = styled.div`
  color: var(--black, #0d0319);

  /* h3 */
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px; /* 145% */
`;

const HeaderExitWrapper = styled.div`
  color: var(--black, #0d0319);

  /* h3 */
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px; /* 145% */
`;

const HeaderExitIconWrapper = styled('div')`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const SignInBodyWrapper = styled.div`
  display: flex;
  padding: 24px var(--corner_card, 16px);
  flex-direction: column;
  align-items: center;
  background: #fff;
`;

const SignInBodyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--corner_card, 16px);
`;

const GoogleSignInWrapper = styled('div')`
  display: flex;
  width: 328px;
  height: 52px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--purple-gray20, #e5e0e9);
  background: var(--white, #fff);
  cursor: pointer;
`;

const GoogleSignInIcon = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

const GoogleSignInText = styled.div`
  color: var(--purple-gray90, #43364c);

  /* h5 */
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 156.25% */
`;

const FacebookSignInWrapper = styled('div')`
  display: flex;
  width: 328px;
  height: 52px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--purple-gray20, #E5E0E9);
  background: var(--white, #FFF);
  cursor: pointer;
`;

const FacebookSignInIcon = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

const FacebookSignInText = styled.div`
  color: var(--purple-gray90, #43364c);

  /* h5 */
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 156.25% */
`;
