import React from "react";
import styled, { keyframes } from "styled-components";
import { truncate } from "lodash";

export const LoadingThumbnailPlaceholder: React.FC = () => (
  <LoadingPlaceHodlerWrapper>
    {[...Array(3)].map((_, index) => (
      <LoadingPlaceHolderColumnWrapper key={index}>
        {[...Array(3)].map((_, index) => (
          <LoadingPlaceHolder key={index} />
        ))}
      </LoadingPlaceHolderColumnWrapper>
    ))}
  </LoadingPlaceHodlerWrapper>
);

interface LoadingProductPlaceholderProps {
  productName: string;
  sectionName: string;
}

export const LoadingProductPlaceholder: React.FC<
  LoadingProductPlaceholderProps
> = ({ productName, sectionName }) => (
  <LoadingPlaceHolderColumnWrapper>
    <LoadingText>
      {truncate(
        `Analyzing the ${sectionName}${
          productName ? " of " + productName : ""
        }`,
        {
          length: 60,
          omission: "",
        }
      ) + "..."}
    </LoadingText>
    {[...Array(4)].map((_, index) => (
      <LoadingPlaceHolder key={index} />
    ))}
  </LoadingPlaceHolderColumnWrapper>
);

const LoadingPlaceHodlerWrapper = styled.div`
  display: flex;
  padding-bottom: 80px;
  align-items: flex-start;
  gap: var(--column-gap, 102px);
  align-self: stretch;
  width: 100%;
`;

const LoadingPlaceHolderColumnWrapper = styled.div<
  React.AllHTMLAttributes<HTMLDivElement>
>`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  margin-top: 20px;
`;

const fadeInOut = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
`;

const LoadingPlaceHolder = styled.div`
  height: 40px;
  align-self: stretch;
  border-radius: 4px;
  border-radius: 4px;
  background: linear-gradient(
    113deg,
    rgba(202, 196, 213, 0.6) -14.67%,
    rgba(202, 196, 213, 0.16) 54.92%,
    rgba(202, 196, 213, 0.6) 126.88%
  );
  opacity: 1;
  animation: ${fadeInOut} 2s infinite;
`;

const LoadingText = styled.div`
  position: absolute;
  width: 210px;
  z-index: 999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: var(--purple-gray90, #43364c);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
`;
