export class CachedStore<T> {
    private _cache: T | null = null;

    public constructor(private _storageKey: string, private _serializer: (data:T) => string = JSON.stringify, private _deserializer: (data: string) => T = JSON.parse, dataTtl: number = 1000*60*5) {
        const localValue = localStorage.getItem(_storageKey);
        if (localValue) {
            this._cache = _deserializer(localValue);
        }
        setInterval(() => {
            this._loadAndDeserialize();
        }, dataTtl);
    }

    public get value(): T | null {
        if (this._cache) return this._cache;
        return this._loadAndDeserialize();
    }

    public set value(data: T | null) {
        this._cache = data;
        if (data === null) {
            this.clear();
            return;
        }
        const serialized = JSON.stringify(data);
        localStorage.setItem(this._storageKey, serialized);
        console.log('cache value persisted: ', serialized);
    }

    public clear() {
        this._cache = null;
        localStorage.removeItem(this._storageKey);
    }

    private _loadAndDeserialize = (): T | null => {
        const localValue = localStorage.getItem(this._storageKey);
        if (localValue) {
            const value = this._deserializer(localValue);
            this._cache = value;
            return value;
        }
        return null;
    }
}