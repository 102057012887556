import React from "react";
import styled, { keyframes } from "styled-components";
import { Thumbnail } from "models/services/api";

interface PreviewSectionProps {
  thumbnails: Thumbnail[];
  isLoading: boolean;
}

const PreviewSection: React.FC<PreviewSectionProps> = ({
  thumbnails,
  isLoading,
}) => (
  <StyledWrapper>
    {isLoading ? (
      <LoadingWrapper>
        {[...Array(3)].map((_, index) => (
          <LoadingPlaceholderWrapper key={index}>
            <LoadingImagePlaceholder />
            <LoadingNamePlaceholder />
          </LoadingPlaceholderWrapper>
        ))}
      </LoadingWrapper>
    ) : (
      thumbnails.map((thumbnail, index) => (
        <PreviewWrapper key={index}>
          {!thumbnail.image_url ||
          !thumbnail.name ||
          !thumbnail.product_id ||
          !thumbnail.url ? (
            <LoadingPlaceholderWrapper>
              <LoadingImagePlaceholder />
              <LoadingNamePlaceholder />
            </LoadingPlaceholderWrapper>
          ) : (
            <>
              <ImageWrapper>
                <Image backgroundImage={thumbnail.image_url} />
              </ImageWrapper>
              <Title>{thumbnail.name}</Title>
            </>
          )}
        </PreviewWrapper>
      ))
    )}
  </StyledWrapper>
);

export default PreviewSection;

const StyledWrapper = styled.div`
  display: flex;
  padding-bottom: 50px;
  align-items: flex-start;
  gap: var(--column-gap, 102px);
  align-self: stretch;
  margin-top: 200px;
`;

const fadeInOut = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
`;

const LoadingPlaceholderWrapper = styled.div<
  React.AllHTMLAttributes<HTMLDivElement>
>`
  display: flex;
  width: var(--3-column, 400px);
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  animation: ${fadeInOut} 2s infinite;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--column-gap, 102px);
  align-self: stretch;
  margin-top: 60px;
`;

const LoadingImagePlaceholder = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 4px;
  background: linear-gradient(
    113deg,
    rgba(202, 196, 213, 0.6) -14.67%,
    rgba(202, 196, 213, 0.16) 54.92%,
    rgba(202, 196, 213, 0.6) 126.88%
  );
`;

const LoadingNamePlaceholder = styled.div`
  height: 66px;
  align-self: stretch;
  border-radius: 4px;
  background: linear-gradient(
    113deg,
    rgba(202, 196, 213, 0.6) -14.67%,
    rgba(202, 196, 213, 0.16) 54.92%,
    rgba(202, 196, 213, 0.6) 126.88%
  );
`;

const PreviewWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 200px;
  height: 200px;
  padding: 11.324px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 11.324px;
  border-radius: 17.524px;
  border: 1.559px solid var(--gray20, #e5e0e9);
  background: var(--white, #fff);
  box-sizing: border-box;
`;

const Image = styled.img<{ backgroundImage: string }>`
  width: 145.626px;
  height: 149.87px;
  flex-shrink: 0;
  background-image: url(${(props) => props.backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const Title = styled.div`
  color: #0d0319;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  align-self: stretch;
  word-break: break-all;
`;
