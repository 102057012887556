import React from "react";

const HeaderLogo: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
    >
      <circle
        cx="8.94013"
        cy="9.79267"
        r="2.62959"
        fill="url(#paint0_linear_2906_4967)"
      />
      <circle
        cx="8.94013"
        cy="18.2077"
        r="2.62959"
        fill="url(#paint1_linear_2906_4967)"
      />
      <circle
        cx="17.3552"
        cy="9.79267"
        r="2.62959"
        fill="url(#paint2_linear_2906_4967)"
      />
      <circle
        cx="24.7184"
        cy="9.79357"
        r="1.57775"
        fill="url(#paint3_linear_2906_4967)"
      />
      <circle
        cx="1.57775"
        cy="9.79357"
        r="1.57775"
        fill="url(#paint4_linear_2906_4967)"
      />
      <circle
        cx="17.3551"
        cy="25.5709"
        r="1.57775"
        fill="url(#paint5_linear_2906_4967)"
      />
      <circle
        cx="8.94103"
        cy="25.5709"
        r="1.57775"
        fill="url(#paint6_linear_2906_4967)"
      />
      <circle
        cx="17.3551"
        cy="2.43029"
        r="1.57775"
        fill="url(#paint7_linear_2906_4967)"
      />
      <circle
        cx="8.94103"
        cy="2.43029"
        r="1.57775"
        fill="url(#paint8_linear_2906_4967)"
      />
      <circle
        cx="24.7184"
        cy="18.2076"
        r="1.57775"
        fill="url(#paint9_linear_2906_4967)"
      />
      <circle
        cx="1.57775"
        cy="18.2076"
        r="1.57775"
        fill="url(#paint10_linear_2906_4967)"
      />
      <circle
        cx="17.3552"
        cy="18.2077"
        r="2.62959"
        fill="url(#paint11_linear_2906_4967)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2906_4967"
          x1="6.57471"
          y1="7.16309"
          x2="13.6739"
          y2="7.9926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2906_4967"
          x1="6.57471"
          y1="15.5781"
          x2="13.6739"
          y2="16.4076"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2906_4967"
          x1="14.9897"
          y1="7.16309"
          x2="22.089"
          y2="7.9926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2906_4967"
          x1="23.2991"
          y1="8.21582"
          x2="27.5587"
          y2="8.71353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2906_4967"
          x1="0.158496"
          y1="8.21582"
          x2="4.41804"
          y2="8.71353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2906_4967"
          x1="15.9358"
          y1="23.9932"
          x2="20.1954"
          y2="24.4909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2906_4967"
          x1="7.52178"
          y1="23.9932"
          x2="11.7813"
          y2="24.4909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2906_4967"
          x1="15.9358"
          y1="0.852539"
          x2="20.1954"
          y2="1.35025"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2906_4967"
          x1="7.52178"
          y1="0.852539"
          x2="11.7813"
          y2="1.35025"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2906_4967"
          x1="23.2991"
          y1="16.6299"
          x2="27.5587"
          y2="17.1276"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2906_4967"
          x1="0.158496"
          y1="16.6299"
          x2="4.41804"
          y2="17.1276"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2906_4967"
          x1="14.9897"
          y1="15.5781"
          x2="22.089"
          y2="16.4076"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8319F5" />
          <stop offset="1" stop-color="#C24087" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HeaderLogo;