import { useState, useEffect } from "react";

type FetchFunction<T> = (...args: any[]) => Promise<T>;

const usePolling = <T,>(
  api: FetchFunction<T>,
  shouldPoll: boolean,
  params: any[],
  interval: number
): { data: T | undefined } => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [hasPendingRequest, setHasPendingRequest] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(
    undefined
  );

  const fetchData = () => {
    if (!hasPendingRequest && shouldPoll) {
      setHasPendingRequest(true);
      api(...params)
        .then((res) => {
          setData(res);
        })
        .finally(() => setHasPendingRequest(false));
    }
  };

  useEffect(() => {
    if (shouldPoll) {
      const id = setInterval(() => {
        fetchData();
      }, interval);

      setIntervalId(id);

      if (shouldPoll) {
        fetchData();
      }
    }
  }, [shouldPoll]);

  useEffect(() => {
    if (!shouldPoll && intervalId) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  }, [shouldPoll, intervalId]);

  return { data };
};

export default usePolling;
