import { apiFetchData } from "../common/apiRequest";
import {
  SearchProductResponse,
  GetSessionImageResponse,
  GetOverviewResponse,
  GetReviewsResponse,
  GetSepecificationsResponse,
  AddProductRsesponse,
  GetBuyingOptionResponse,
  RemoveProductFromSessionResponse,
  SendFeedbackResponse,
  SendFeedbackContentResponse,
  SendChatResponse,
  GetCustomSections,
} from "models/services/api";

export const searchProducts = async (
  query: string,
  sessionId: string
): Promise<SearchProductResponse> =>
  apiFetchData<SearchProductResponse>({
    url: "/search",
    method: "GET",
    params: {
      session_id: sessionId,
      query: encodeURIComponent(query),
    },
  });

export const getSessionImage = async (
  sessionId: string
): Promise<GetSessionImageResponse> =>
  apiFetchData<GetSessionImageResponse>({
    url: "/session/image",
    method: "GET",
    params: {
      session_id: sessionId,
    },
  });

export const addProduct = async (
  sessionId: string,
  url: string
): Promise<AddProductRsesponse> =>
  apiFetchData<AddProductRsesponse>({
    url: "/product",
    method: "POST",
    params: {
      session_id: sessionId,
      url,
    },
  });

export const upsertProductByURL = async (
  sessionId: string,
  productURL: string
): Promise<GetSessionImageResponse> =>
  apiFetchData<GetSessionImageResponse>({
    url: "/product",
    method: "POST",
    params: {
      session_id: sessionId,
      url: productURL,
    },
  });

export const getOverview = async (
  sessionId: string
): Promise<GetOverviewResponse> =>
  apiFetchData<GetOverviewResponse>({
    url: "/poll/overview",
    method: "GET",
    params: {
      session_id: sessionId,
    },
  });

export const getReviews = async (
  sessionId: string
): Promise<GetReviewsResponse> =>
  apiFetchData<GetReviewsResponse>({
    url: "/poll/reviews",
    method: "GET",
    params: {
      session_id: sessionId,
      preference_id: "45f4e031-5a83-4b6b-923d-30a0b2c7cd6f",
    },
  });

export const getSpecifications = async (
  sessionId: string
): Promise<GetSepecificationsResponse> =>
  apiFetchData<GetSepecificationsResponse>({
    url: "/poll/specifications",
    method: "GET",
    params: {
      session_id: sessionId,
    },
  });

export const getBuyingOptions = async (
  sessionId: string
): Promise<GetBuyingOptionResponse> =>
  apiFetchData<GetBuyingOptionResponse>({
    url: "/buying-options",
    method: "GET",
    params: {
      session_id: sessionId,
    },
  });

export const removeProductFromSession = async (
  sessionId: string,
  productId: string
): Promise<RemoveProductFromSessionResponse> =>
  apiFetchData<RemoveProductFromSessionResponse>({
    url: "/product/compare",
    method: "DELETE",
    params: {
      product_id: productId,
      session_id: sessionId,
    },
  });

export type SectionName =
  | "Overview"
  | "Buying Options"
  | "Specifications"
  | "Ratings & Reviews";

export const AllowedSetionNames = (name: string): name is SectionName => {
  return [
    "Overview",
    "Buying Options",
    "Specifications",
    "Ratings & Reviews",
  ].includes(name);
};

export const sendFeedbackContent = async (
  sectionId: string,
  sectionName: SectionName,
  feedback: string
): Promise<SendFeedbackContentResponse> =>
  apiFetchData<SendFeedbackContentResponse>({
    url: "/section/feedback",
    method: "POST",
    params: {
      session_id: sectionId,
      section_name: sectionName,
      feedback: feedback,
    },
  });

export const sendFeedback = async (
  sectionId: string,
  sectionName: SectionName,
  isLike: boolean
): Promise<SendFeedbackResponse> =>
  apiFetchData<SendFeedbackResponse>({
    url: "/section/like",
    method: "POST",
    params: {
      session_id: sectionId,
      section_name: sectionName,
      like: isLike,
    },
  });

export const sendChat = async (
  sessionId: string,
  message: string
): Promise<SendChatResponse> =>
  apiFetchData<SendChatResponse>({
    url: "/session/chat",
    method: "POST",
    params: {
      session_id: sessionId,
      message,
    },
  });

export const getCustomSections = async (
  sessionId: string
): Promise<GetCustomSections> =>
  apiFetchData<GetCustomSections>({
    url: "/poll/custom_sections",
    method: "GET",
    params: {
      session_id: sessionId,
    },
  });
