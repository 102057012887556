import React from "react";

const BackIcon: React.FC = () => {
  return (
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9.66191 16.0518L16.5765 22.9664L14.817 24.6951L4.93896 14.817L14.817 4.93896L16.5765 6.66762L9.66191 13.5823H24.6951V16.0518H9.66191Z"
              fill="#0D0319"/>
      </svg>
  );
};
export default BackIcon;
