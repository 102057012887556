import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";

import PromptLogo from "../icon/PromptLogo";
import ChevronUpIcon from "../icon/ChevronUpIcon";
import { parseJsonToObject } from "../../common/json";
import { sendChat } from "../../services/product";
import {
  SendChatResponse,
  ChatGenerateSectionResponse,
  ChatUpdateSectionResponse,
  ChatProductSuggestionsResponse,
} from "models/services/api";
import { getChatHistory } from "../../services/chat";
import PromptGreetings from "./PromptGreetings";
import PromptSection, { SectionContent } from "./PromptSection";

interface PromptProps {
  sessionId: string;
  onSearch: (res: SendChatResponse) => void;
  productUrls: string[];
  onAddProduct: () => void;
}

const processChatResponse = (res: SendChatResponse) => {
  if (res.type === "Generate Section") {
    const convertedRes = res as ChatGenerateSectionResponse;
    return `Generated the new "${convertedRes.name} section".`;
  } else if (res.type === "Update Section") {
    const convertedRes = res as ChatUpdateSectionResponse;
    return `Updated "${convertedRes.name} section".`;
  } else if (res.type === "Add Product") {
    return `The product has been added.`;
  } else if (res.type === "Delete Product") {
    return `The product has been deleted.`;
  } else if (!res.type && res.response) {
    return res.response;
  }
  return "";
};

const Prompt: React.FC<PromptProps> = ({
  sessionId,
  onSearch,
  productUrls,
  onAddProduct,
}) => {
  const [inputText, setInputText] = useState<string>("");
  const [isLoadingChatHistory, setLoadingChatHistory] =
    useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [isLoadingResponse, setIsLoadingResponse] = useState<boolean>(false);
  const [promptSections, setPromptSections] = useState<SectionContent[]>([]);

  const submitQuery = (inputText: string) => {
    const originalText = inputText;
    setPromptSections((promptSections) => [
      ...promptSections,
      { content: originalText, layoutScheme: "question" },
      { content: "AI Analyzing...", layoutScheme: "response" },
    ]);
    setInputText("");

    sendChat(sessionId, inputText)
      .then((res) => {
        setPromptSections((promptSections) => {
          const newPromptSections = [
            ...promptSections.slice(0, promptSections.length - 1),
            {
              content: processChatResponse(res),
              layoutScheme: "response",
            } as SectionContent,
          ];
          if (!res.type && (res as ChatProductSuggestionsResponse).results) {
            newPromptSections.push({
              layoutScheme: "productSuggestions",
              content: (res as ChatProductSuggestionsResponse).results,
            });
          }
          return newPromptSections;
        });
        if (res) {
          onSearch(res);
        }
        setIsLoadingResponse(false);
      })
      .catch((err) => {
        console.error("failed to sent chat text due to ", JSON.stringify(err));
        // clear failed messages
        setPromptSections((promptSections) => promptSections.slice(0, -2));
        setInputText(originalText);
        setIsLoadingResponse(false);
        alert("Failed to send last message, please retry");
      });
  };

  useEffect(() => {
    setLoadingChatHistory(true);
    getChatHistory(sessionId).then((messages) => {
      if (messages.length) {
        const newPromptSections: SectionContent[] = messages.map((message) => {
          if (message.role === "user") {
            return {
              layoutScheme: "question",
              content: message.message,
            };
          } else if (message.role === "system") {
            const response = parseJsonToObject(
              message.message
            ) as SendChatResponse;
            if (
              !response.type &&
              (response as ChatProductSuggestionsResponse).results
            ) {
              return {
                layoutScheme: "productSuggestions",
                content: (response as ChatProductSuggestionsResponse).results,
              };
            } else {
              return {
                layoutScheme: "response",
                content: processChatResponse(response),
              };
            }
          } else {
            return {
              layoutScheme: "unknown",
              content: "",
            };
          }
        });
        setPromptSections((promptSections) => [
          ...promptSections,
          ...newPromptSections,
        ]);
      }
      setLoadingChatHistory(false);
    });
  }, [sessionId, setLoadingChatHistory]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (
        sessionId &&
        event.key === "Enter" &&
        inputText &&
        !isLoadingResponse
      ) {
        setIsLoadingResponse(true);
        submitQuery(inputText);
      }
    },
    [inputText]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputText(event.target.value);
    },
    [setInputText]
  );

  const handleToggleClick = useCallback(() => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
  }, [setIsModalOpen]);

  useEffect(() => {
    if (isModalOpen && promptSections.length > 0) {
      const promptResponses =
        document.getElementsByClassName("prompt-response");
      const dialogWrapper = document.getElementById("dialog-wrapper");
      if (promptResponses.length > 0) {
        const promptResponse = promptResponses[promptResponses.length - 1];
        dialogWrapper?.scrollTo({
          top: dialogWrapper.scrollHeight - promptResponse.clientHeight - 25,
          behavior: "smooth",
        });
      }
    }
  }, [isModalOpen, promptSections]);

  return (
    <>
      {isModalOpen ? (
        <PromptWrapper>
          <DialogHeader>
            <DialogTitleWrapper>
              <PromptLogo />
              <DialogTitle>Shop4u AI</DialogTitle>
            </DialogTitleWrapper>
            <DialogToggleWrapper onClick={handleToggleClick}>
              <DialogToggleIcon>
                <ChevronUpIcon />
              </DialogToggleIcon>
            </DialogToggleWrapper>
          </DialogHeader>
          <DialogWrapper id="dialog-wrapper">
            {!isLoadingChatHistory && !promptSections.length && (
              <PromptGreetings
                sessionId={sessionId}
                onSuggestionSelected={submitQuery}
              />
            )}
            {promptSections.map((promptSection) => (
              <>
                <PromptSection
                  sectionContent={promptSection}
                  cls="prompt-response"
                  productUrls={productUrls}
                  onAddProduct={onAddProduct}
                  sessionId={sessionId}
                />
              </>
            ))}
          </DialogWrapper>
          <DialogFooter>
            <InputWrapper>
              <Input
                autoFocus
                type="text"
                onKeyDown={handleKeyDown}
                value={inputText}
                onChange={handleInputChange}
                placeholder="Ask questions to learn more"
              ></Input>
            </InputWrapper>
          </DialogFooter>
        </PromptWrapper>
      ) : (
        <CollapsedPromptWrapper>
          <CollapsedPromptHeader>
            <CollapsedPromptTitleWrapper>
              <PromptLogo />
              <CollapsedPromptTitle>Shop4u AI</CollapsedPromptTitle>
            </CollapsedPromptTitleWrapper>
            <CollapsedPromptButton onClick={handleToggleClick}>
              <CollapsedPromptButtonIcon>
                <ChevronUpIcon />
              </CollapsedPromptButtonIcon>
            </CollapsedPromptButton>
          </CollapsedPromptHeader>
          <CollapsedInputWrapper
            type="text"
            onFocus={handleToggleClick}
            placeholder="Make specifications easy to understand"
          ></CollapsedInputWrapper>
        </CollapsedPromptWrapper>
      )}
    </>
  );
};

export default Prompt;

const PromptWrapper = styled.div`
  display: flex;
  width: 412px;
  height: 70vh;
  padding: 0px 8px 8px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid var(--brand, #8319f5);
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
  box-shadow: 0px 4px 16px 8px rgba(67, 54, 76, 0.12);
`;

const DialogWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  overflow: auto;
  display: flex;
  padding: var(--corner_card, 16px);
  flex-direction: column;
  align-items: center;
  gap: var(--corner_card, 16px);
  flex: 1 0 0;
  align-self: stretch;
  background: #fff;
  border-radius: 10px 10px 0 0;
`;

const DialogHeader = styled.div`
  display: flex;
  padding: 6px 8px 6px 20px;
  align-items: center;
  gap: 16.216px;
  align-self: stretch;
  border-bottom: 0.811px solid var(--transparent20, rgba(0, 0, 0, 0.04));
`;

const DialogTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6.486px;
  flex: 1 0 0;
`;

const DialogTitle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--white, #fff);
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
`;

const DialogToggleWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const DialogToggleIcon = styled.div`
  display: flex;
  transform: rotate(180deg);
  svg {
    width: 24px;
    height: 24px;
    g path {
      fill: white;
    }
  }
`;

const DialogFooter = styled.div`
  display: flex;
  padding: 20px var(--corner_card, 16px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: #fff;
  border-radius: 0 0 10px 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--gray20, #e5e0e9);
  background: var(--gray10, #f7f6f9);
`;

const Input = styled.input<React.AllHTMLAttributes<HTMLInputElement>>`
  width: 100%;
  color: var(--gray70, #74697b);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  background: var(--gray10, #f7f6f9);
  border: unset;

  &:focus {
    border: unset;
    outline: none;
  }
`;

const CollapsedPromptWrapper = styled.div`
  display: flex;
  width: var(--3-column, 396px);
  padding: 0px 12px 14px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: var(--corner_card, 16px);
  border-bottom: 0.811px solid var(--transparent20, rgba(0, 0, 0, 0.04));
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
  box-shadow: 0px 2px 8px 8px rgba(67, 54, 76, 0.24);
  transition: max-height 0.5s ease, max-width 0.5s ease;
`;

const CollapsedPromptHeader = styled.div`
  display: flex;
  padding: 4px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const CollapsedPromptTitleWrapper = styled.div`
  display: flex;
  width: 129.486px;
  align-items: center;
  gap: 6.486px;
  overflow: hidden;
  color: var(--white, #fff);
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
`;

const CollapsedPromptTitle = styled.div``;

const CollapsedPromptButton = styled.div<
  React.AllHTMLAttributes<HTMLDivElement>
>`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const CollapsedPromptButtonIcon = styled.div`
  display: flex;
  svg {
    width: 24px;
    height: 24px;
    g path {
      fill: white;
    }
  }
`;

const CollapsedInputWrapper = styled.input<
  React.AllHTMLAttributes<HTMLInputElement>
>`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--gray20, #e5e0e9);
  background: var(--gray10, #f7f6f9);
  color: var(--gray70, #74697b);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;
