import React from "react";

const SmallCleanIcon: React.FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="x">
        <path
          id="Vector"
          d="M10.4162 3.6543L3.72412 10.3463"
          stroke="#74697B"
          strokeWidth="1.11534"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M3.72412 3.6543L10.4162 10.3463"
          stroke="#74697B"
          strokeWidth="1.11534"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export default SmallCleanIcon;
