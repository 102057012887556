import { ListSessionProductsResponse } from "../models/services/product";
import { apiFetchData } from "../common/apiRequest";

export const listSessionProducts = async (
  pageNumber = 1,
  pageSize = 10
): Promise<ListSessionProductsResponse> => {
  return apiFetchData<ListSessionProductsResponse>({
    url: "/session_products",
    params: {
      page: pageNumber,
      per_page: pageSize,
    },
    method: "GET",
  });
};

export const exchangeSessionIdBySnapshotName = async (
  snapshotName: string
): Promise<string> => {
  return apiFetchData<{ session_id: string }>({
    url: snapshotName,
    method: "GET",
  }).then((json) => json.session_id);
};
