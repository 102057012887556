import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { getSuggestedQuestions } from "../../services/chat";
import PromptLogo from "../icon/PromptLogo";
import LightBulb from "../icon/LightBulb";

interface PromptGreetingsProps {
  sessionId: string;
  onSuggestionSelected: (suggestion: string) => void;
}

const PromptGreetings: React.FC<PromptGreetingsProps> = (
  props: PromptGreetingsProps
) => {
  const { sessionId, onSuggestionSelected } = props;
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [fetchSuggestionFailure, setFetchSuggestionFailure] = useState(false);
  useEffect(() => {
    getSuggestedQuestions(sessionId)
      .then((suggestions) => {
        setSuggestions(suggestions);
      })
      .catch((error) => {
        console.error(error);
        setFetchSuggestionFailure(true);
      });
  }, [sessionId]);

  return (
    <GreetingsWrapper>
      <HeaderWrapper>
        <HeaderLogo>
          <PromptLogo size="large" />
        </HeaderLogo>
        <HeaderTitle>Shop4u AI</HeaderTitle>
        <HeaderContent>
          Quickly search for answers or change comparison preferences.
        </HeaderContent>
      </HeaderWrapper>
      <SectionsWrapper>
        <SectionWrapper>
          <SectionHeader>Ask these questions</SectionHeader>
          <SectionBody>
            {suggestions.map((suggestion) => (
              <>
                <SectionItem>
                  <SectionItemIcon>
                    <LightBulb />
                  </SectionItemIcon>
                  <SectionItemText
                    onClick={() => onSuggestionSelected(suggestion)}
                  >
                    {suggestion}
                  </SectionItemText>
                </SectionItem>
              </>
            ))}
          </SectionBody>
        </SectionWrapper>
        {/* we don't have suggested questions for those sections for now...
                <SectionWrapper>
                    <SectionHeader>Change preferences</SectionHeader>
                    <SectionBody>
                        <SectionItem>
                            <SectionItemIcon><LightBulb /></SectionItemIcon>
                            <SectionItemText>some text</SectionItemText>
                        </SectionItem>
                    </SectionBody>
                </SectionWrapper>
                <SectionWrapper>
                    <SectionHeader>Or you can...</SectionHeader>
                    <SectionBody>
                        <SectionItem>
                            <SectionItemIcon><LightBulb /></SectionItemIcon>
                            <SectionItemText>some text</SectionItemText>
                        </SectionItem>
                    </SectionBody>
                </SectionWrapper>
                    */}
      </SectionsWrapper>
    </GreetingsWrapper>
  );
};

export default PromptGreetings;

const GreetingsWrapper = styled.div`
  display: flex;
  padding: 40px var(--corner_card, 16px) var(--corner_card, 16px)
    var(--corner_card, 16px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--corner_card, 16px) var(--corner_card, 16px) 0px 0px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 301px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const HeaderLogo = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 17px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
`;

const HeaderTitle = styled.div`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px; /* 145% */
`;

const HeaderContent = styled.div`
  align-self: stretch;
  color: var(--purple-gray70, #74697b);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
`;

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--corner_card, 16px);
  align-self: stretch;
`;

const SectionHeader = styled.div`
  color: var(--purple-gray90, #43364c);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 156.25% */
`;

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;
const SectionItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;
const SectionItemIcon = styled.div`
  display: flex;
  padding: 2px;
  align-items: center;
  gap: 10px;
  width: 20px;
  height: 20px;
`;

const SectionItemText = styled("div")`
  flex: 1 0 0;
  cursor: pointer;
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
`;
