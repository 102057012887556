import { getCurrentUserProfile } from '../services/user';
import { tokenStore } from '../stores';
import { notification } from '../common/notification';
import { EVT_USER_LOGGED_IN, EVT_USER_PROFILE_UPDATE } from '../events';
import { jobManager } from '../common/jobManager';

const JOB_NAME = 'userProfileUpdateJob';

notification.addListener(EVT_USER_LOGGED_IN, async () => {
    jobManager.refreshJob(JOB_NAME);
});

const userProfileUpdateJobExecutor = async () => {
    if (!tokenStore.value) {
        console.log('no token is present, skipping user profile update job');
        return;
    }
    const newProfiles = await getCurrentUserProfile();
    notification.emit(EVT_USER_PROFILE_UPDATE, newProfiles);
};

const USER_PROFILE_UPDATE_JOB = {
    name: JOB_NAME,
    intervalSeconds: 60 * 60 * 24,
    executor: userProfileUpdateJobExecutor
};

jobManager.addJob(USER_PROFILE_UPDATE_JOB);