import { useHistory } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import BackIcon from "../../components/icon/BackIcon";

export const PrivacyPolicyPage: React.FC = () => {
  const history = useHistory();
  return (
    <StyledWrapper>
      <NavWrapper>
        <IconWrapper>
          <NavName onClick={() => {
            history.push('/');
          }}>
          <BackIcon />
        </NavName>
        </IconWrapper>
      </NavWrapper>
      <PrivacyPolicyContent/>
    </StyledWrapper>
  );
};

const PrivacyPolicyContent = () => {
  return (
    <>
      <h1>Privacy Policy</h1>
      <p>Last updated: September 13, 2024</p>
      <p>
        This Privacy Policy (this “Policy”) describes how Shop4u collects, uses, discloses, and protects information about you when you use our websites and other online products and services (collectively, our “Services”). We may change this Privacy Policy from time to time, and we will notify you by posting the revised Policy on our website and updating the “Last updated” date above. We encourage you to review this Policy whenever you use our Services or interact with us to stay informed about our information practices and the choices available to you.
      </p>
      <h2>Our Services</h2>
      <p>
        We provide a product comparison service that utilizes information scraped from the internet, enhanced by a chat-based interface powered by large language models (LLMs). Our Service is accessible at <a href="https://shop4u.ai">https://shop4u.ai</a>.
      </p>
      <h2>Collection of Information</h2>
      <h3>Information We Collect Automatically</h3>
      <p>
        When you visit our website, certain information may be automatically collected due to your use of the Services or from the Services infrastructure, including data about the device and network you use, such as your IP address, device type, operating system version, unique device identification numbers, browser type, broad geographic location and other technical information. We also collect information about how your device has interacted with our website, including your access times, pages viewed, links clicked, and the page you visited before navigating to our Services.
      </p>
      <p>
        A “Cookie” is a small file placed on your device. You can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our Services. Unless you have adjusted your browser setting so that it will refuse Cookies, our Services may use Cookies.
      </p>
      <p>
        A “Web Beacon” is a small electronic file (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited certain pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
      </p>
      <p>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when you go offline, while Session Cookies are deleted as soon as you close your web browser.
      </p>
      <h3>Information You Directly Provide</h3>
      <p>We collect information you provide directly, which may include:</p>
      <ul>
        <li>Username</li>
        <li>Email address</li>
        <li>Password</li>
        <li>Any other information you choose to provide</li>
      </ul>
      <p>
        “Personal Data” is any information that relates to an identified or identifiable individual (e.g. emails).
      </p>
      <h3>Information We Do Not Collect</h3>
      <p>
        We do not knowingly collect sensitive information, including details regarding race, political views, or health status.
      </p>
      <h2>Use of Information</h2>
      <p>We may use Personal Data for the following purposes:</p>
      <ul>
        <li>To provide, maintain, and improve our Services.</li>
        <li>To manage your account and provide access to functionalities.</li>
        <li>To contact you with updates or important information.</li>
        <li>To manage your requests: To attend and manage your requests to Us.</li>
        <li>For business transfers: To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by us about our Services is among the assets transferred.</li>
        <li>For other purposes: We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</li>
      </ul>
      <h2>Sharing of Information</h2>
      <p>We do not sell or trade your Personal Data. We may share your information in the following circumstances:</p>
      <ul>
        <li>With service providers to monitor and analyze usage.</li>
        <li>In connection with business transfers or mergers.</li>
        <li>With your consent or at your request.</li>
        <li>As required by law or to protect our rights.</li>
      </ul>
      <p>
        The security of Personal Data is important to us, but please be advised that no method of transmission or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect Personal Data, we cannot guarantee its absolute security.
      </p>
      <h2>Third Party Services</h2>
      <p>
        Our Services may integrate third-party services (e.g., APIs for LLMs). Personal Data will not be transmitted unless necessary for service continuity.
      </p>
      <p>
        We may use site monitoring tools to collect anonymized information about usage. These tools may collect your IP address, device type, browser type, operating system, geographic location, and other technical information. We use this information to improve the quality and relevance of our website to our visitors.
      </p>
      <h2>Security</h2>
      <p>
        We implement reasonable safeguards to protect your Personal Data. Your data will be stored on secure servers, and while we strive for high security, no method of transmission or storage is 100% secure. Your data will be stored on Microsoft Azure in accordance with its applicable policies and requirements data storage and retention (accessible here: <a href="https://azure.microsoft.com/en-us/resources/azure-privacy-and-security/">Privacy in Azure</a>). We make no independent representations or warranties as to the policies, practices, or procedures of any third party.
      </p>
      <h2>Data Retention</h2>
      <p>
        We retain Personal Data as long as your account is active. You can request deletion of your data by contacting <a href="mailto:support@shop4u.ai">support@shop4u.ai</a>. We will delete your account data within 30 days of account closure.
      </p>
      <h2>Your Rights</h2>
      <p>
        You have the right to request access to, correction of, or deletion of your Personal Data. You may also withdraw consent for data processing at any time.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Policy, please contact us at <a href="mailto:support@shop4u.ai">support@shop4u.ai</a>.
      </p>
    </>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  background: #f7f6f9;
  padding-top: 30px;
  position: fixed;
  top: 0;
  z-index: 9999;
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const NavName = styled("div")`
  height: 30px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #0d0319;
`;

