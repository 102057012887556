import React from "react";
import { truncate } from "lodash";
import styled from "styled-components";
import { Thumbnail } from "models/services/api";
import Banner from "./TopBanner";

interface CompactHeaderProps {
  thumbnails: Thumbnail[];
}

const CompactHeaderProps: React.FC<CompactHeaderProps> = ({ thumbnails }) => {
  return (
    <CompactHeaderWrapper>
      <VerticalAlignedWrapper>
        <Banner />
        <SelectedProductsWrapper>
          {thumbnails.map((thumbnail, index) => (
            <SelectedProductWrapper key={index}>
              {!thumbnail.image_url ? (
                <MiniLoadingImagePlaceholder />
              ) : (
                <ProductThumbnailWrapper>
                  <ProductThumbnail src={thumbnail.image_url} />
                </ProductThumbnailWrapper>
              )}
              {!thumbnail.name ? (
                <MiniLoadingNamePlaceholder />
              ) : (
                <ProductName>
                  {truncate(thumbnail.name, {
                    length:
                      thumbnails.length === 2
                        ? 100
                        : thumbnails.length === 3
                        ? 50
                        : 35,
                  })}
                </ProductName>
              )}
            </SelectedProductWrapper>
          ))}
        </SelectedProductsWrapper>
      </VerticalAlignedWrapper>
    </CompactHeaderWrapper>
  );
};

export default CompactHeaderProps;

const CompactHeaderWrapper = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--gray20, #e5e0e9);
  box-shadow: 0px 2px 8px 8px rgba(67, 54, 76, 0.24);
  position: fixed;
  z-index: 9999;
  background: var(--gray10, #f7f6f9);
  width: 100%;
  left: 0;
`;

const VerticalAlignedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SelectedProductsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--column-gap, 102px);
  align-self: stretch;
  width: 1400px;
`;

const SelectedProductWrapper = styled.div<
  React.AllHTMLAttributes<HTMLDivElement>
>`
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
`;

const ProductThumbnailWrapper = styled.div`
  display: flex;
  padding: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.037px;
  border-radius: 4.291px;
  border: 1.56px solid var(--gray20, #e5e0e9);
  background: var(--white, #fff);
`;

const ProductThumbnail = styled.img<React.AllHTMLAttributes<HTMLImageElement>>`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

const ProductName = styled.div`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  white-space: break-spaces;
`;

const MiniLoadingImagePlaceholder = styled.div`
  width: 49px;
  height: 49px;
  border-radius: 4px;
  background: linear-gradient(
    113deg,
    #e7e7e7 -14.67%,
    #f2f2f2 54.92%,
    #e8e8e8 126.88%
  );
`;

const MiniLoadingNamePlaceholder = styled.div`
  height: 39px;
  align-self: center;
  border-radius: 4px;
  background: linear-gradient(
    113deg,
    #e7e7e7 -14.67%,
    #f2f2f2 54.92%,
    #e8e8e8 126.88%
  );
  width: 190px;
`;
