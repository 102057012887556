type Props = {
    style?: Record<string, string>;
    onClick?: () => void;
};

const RightArrow = (props: Props) =>
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" style={props.style ?? {}} onClick={props.onClick}>
        <path d="M11.6223 9.08251L8.44227 5.90251C8.37255 5.83222 8.2896 5.77642 8.19821 5.73834C8.10681 5.70027 8.00878 5.68066 7.90977 5.68066C7.81077 5.68066 7.71274 5.70027 7.62134 5.73834C7.52995 5.77642 7.447 5.83222 7.37727 5.90251C7.23759 6.04303 7.15918 6.23312 7.15918 6.43126C7.15918 6.6294 7.23759 6.81949 7.37727 6.96001L10.0323 9.61501L7.37727 12.27C7.23759 12.4105 7.15918 12.6006 7.15918 12.7988C7.15918 12.9969 7.23759 13.187 7.37727 13.3275C7.44735 13.397 7.53047 13.452 7.62184 13.4893C7.71322 13.5267 7.81107 13.5456 7.90977 13.545C8.00848 13.5456 8.10633 13.5267 8.1977 13.4893C8.28908 13.452 8.37219 13.397 8.44227 13.3275L11.6223 10.1475C11.6926 10.0778 11.7484 9.99484 11.7864 9.90345C11.8245 9.81205 11.8441 9.71402 11.8441 9.61501C11.8441 9.516 11.8245 9.41797 11.7864 9.32658C11.7484 9.23519 11.6926 9.15223 11.6223 9.08251Z" fill={props.style?.color ?? "#8319F5"} />
    </svg>

export default RightArrow;