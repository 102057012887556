import { refreshToken } from '../services/user';
import { tokenStore } from '../stores';
import { notification } from '../common/notification';
import { EVT_TOKEN_REFRESHED, EVT_USER_LOGGED_IN } from '../events';
import { jobManager } from '../common/jobManager';

const JOB_NAME = 'tokenRefreshJob';

notification.addListener(EVT_USER_LOGGED_IN, () => {
    jobManager.refreshJob(JOB_NAME);
});

const tokenRefreshJobExecutor = async () => {
    if (!tokenStore.value) {
        // no token found
        return;
    }
    const newTokens = await refreshToken();
    console.log('refreshed token:', JSON.stringify(newTokens));
    tokenStore.value = newTokens;
    notification.emit(EVT_TOKEN_REFRESHED, newTokens);
};

const TOKEN_REFRESH_JOB = {
    name: JOB_NAME,
    intervalSeconds: 60 * 60 * 12,
    executor: tokenRefreshJobExecutor,
    version: 1,
};

jobManager.addJob(TOKEN_REFRESH_JOB);