import { mpTrack } from '../common/mixPanel';

export const USER_TOKEN_STORAGE_KEY = 'shop4u.authtoken';

export function getUserTokenFromLocalStorage(key: string = USER_TOKEN_STORAGE_KEY): any {
  // Get from local storage by key
  const item = localStorage.getItem(key);
  try {
    // Parse stored json or if none return initialValue
    return item?.startsWith('"') ? JSON.parse(item) : item;
  } catch (error) {
    mpTrack('error', { name: 'getUserTokenFromLocalStorage', error: error });
    return null;
  }
}

export function clearUserTokenInLocalStorage(): void {
  localStorage.removeItem(USER_TOKEN_STORAGE_KEY);
}

export function setUserTokenInLocalStorage(token: string): void {
  localStorage.setItem(USER_TOKEN_STORAGE_KEY, JSON.stringify(token));
}
