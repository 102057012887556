import styled from "styled-components";
import React, { useState } from "react";
import CloseBlackIcon from "../../components/icon/CloseBlackIcon";
import { SectionName, sendFeedbackContent } from "../../services/product";

interface FormProps {
  changeFormShow: () => void;
  openMsgShow: () => void;
  sessionId: string;
  sectionName: SectionName;
  isLike: boolean;
}

const FeedbackForm: React.FC<FormProps> = ({
  changeFormShow,
  openMsgShow,
  sessionId,
  sectionName,
}) => {
  const [value, setValue] = useState<string>("");
  const submitFeedback = (value: string): void => {
    changeFormShow();
    openMsgShow();
    sendFeedbackContent(sessionId, sectionName, value);
  };

  return (
    <>
      <Feedback>
        <div onClick={() => changeFormShow()}>
          <CloseMsgForm>
            <CloseBlackIcon />
          </CloseMsgForm>
        </div>
        <FormTitle>Help us improve</FormTitle>
        <textarea
          placeholder="Let us know if you have any additional feedback orsuggestions (optlonal)"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setValue(e.target.value)
          }
        ></textarea>
        <div onClick={() => submitFeedback(value)}>
          <SubmitForm>Submit feedback</SubmitForm>
        </div>
      </Feedback>
    </>
  );
};

export default FeedbackForm;

const CloseMsgForm = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const Feedback = styled.form`
  position: absolute;
  top: -270px;
  right: 0;
  width: 400px;
  height: 220px;
  border-radius: 10px;
  padding: 10px;

  background: white;
  border: 3px solid rgb(124, 37, 229);
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.5);

  & textarea {
    width: 100%;
    height: 100px;
    background: red;
    resize: none;
    outline: none;
    margin-top: 15px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    background: rgb(247, 246, 249);
  }

  & textarea::placeholder {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    color: rgb(164, 158, 170);
  }
`;

const FormTitle = styled.h4`
  font-size: 20px;
  font-weight: 600;
  line-heght: 20px;
  margin: 0;
  padding-bottom: 12px;

  border-bottom: 2px solid rgb(247, 246, 249);
`;

const SubmitForm = styled.button`
  padding: 10px 15px;
  background: linear-gradient(to right, rgb(120, 35, 236), rgb(163, 63, 159));
  border: 0;
  border-radius: 10px;
  color: white;
  margin-top: 17px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;
